import { useMutation } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { useAlert } from 'src/lib/useAlert.tsx'
import { getKyError } from 'src/utils/fetch-utils.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

const updateBUPassword = async (httpInstance: KyInstance, buAuth0Id: string, password: string) => {
	await httpInstance.post(`businesses/user/password`, {
		json: {
			password,
			auth0_user_id: buAuth0Id,
		},
	})
}

export function useUpdateBUPassword(onSuccess: () => void) {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()

	return useMutation<void, Error, { buAuth0Id: string; password: string }, string>({
		mutationFn: async ({ buAuth0Id, password }) => {
			return updateBUPassword(paymentServiceClient.httpInstance, buAuth0Id, password)
		},
		onSuccess: async () => {
			triggerSnackbar('Password updated successfully', 'success')
			onSuccess()
		},
		onError: async (error) => {
			console.error(error)
			const errorMessage = await getKyError(error)
			triggerSnackbar(errorMessage || 'Password update failed', 'error')
		},
	})
}
