import { Box, Button, Typography } from '@mui/material'

import { COLOR } from '../../layout/constants'

type Props = {
	title?: string | JSX.Element
	image: {
		src: string
		margin?: string
	}
	imageWidth?: number
	content?: JSX.Element
	buttom: { text: string; onClick: () => void }
	top?: { margin?: string }
}
export const AlertPage = ({ top, title, image, imageWidth, content, buttom }: Props) => {
	return (
		<Box
			data-testid="AlertPage"
			sx={{
				margin: top?.margin || '30px 17px 20px',
			}}
		>
			{title && (
				<Typography
					sx={{
						fontSize: '20px',
						fontWeight: '700',
						lineHeight: '25px',
						textAlign: 'center',
					}}
				>
					{title}
				</Typography>
			)}
			<Box sx={{ display: 'flex', justifyContent: 'center', margin: image.margin || '100px 0px 50px' }}>
				<img
					src={image.src}
					width={imageWidth}
				/>
			</Box>
			{content}
			<Button
				variant="contained"
				color="inherit"
				sx={{
					textTransform: 'uppercase',
					width: '100%',
					padding: '10px 0',
					color: COLOR.successText,
				}}
				onClick={buttom.onClick}
			>
				{buttom.text}
			</Button>
		</Box>
	)
}
