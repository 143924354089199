import { useMutation, useQueryClient } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTimeout } from 'usehooks-ts'

import { PurchaseResponse } from 'src/@types/purchase'
import { useAppStore } from 'src/store/appStore.ts'
import { Purchase } from '../../models/purchase.models.ts'
import { ErrorCode, parseHttpError } from '../errors.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

async function createPurchase(httpInstance: KyInstance) {
	return await httpInstance.post('purchases').json<Purchase>()
}

export function usePurchase() {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const [renderLinkAccountModal, setRenderLinkAccountModal] = useState(false)
	const { paymentServiceClient } = usePaymentService()
	const setPurchaseResult = useAppStore((state) => state.setPurchaseResult)
	const [qrCodeExpired, setQrCodeExpired] = useState(false)
	const [timeRemaining, setTimeRemaining] = useState(0)

	useTimeout(() => {
		if (purchase) {
			setQrCodeExpired(true)
			setTimeRemaining(0)
		}
	}, timeRemaining)

	const updateQrCode = useCallback((data: Purchase) => {
		const expirationTime = new Date(data.Expiration).getTime()
		const currentTime = new Date().getTime()
		const timeRemainingCounter = expirationTime - currentTime - 10000
		setTimeRemaining(timeRemainingCounter)
		setQrCodeExpired(false)
	}, [])

	const {
		mutate: addPurchase,
		error: unhandledError,
		data: purchase,
		...other
	} = useMutation<Purchase, HTTPError>({
		mutationFn: async () => {
			return createPurchase(paymentServiceClient.httpInstance)
		},
		onSuccess: async (data: Purchase) => {
			queryClient.setQueryData<Purchase>(['purchase'], data)
			updateQrCode(data)
		},
		onError: async (error) => {
			const { parsed, data } = await parseHttpError(error)
			if (parsed && data!.code === ErrorCode.ErrExternalAccountNotFound) {
				setRenderLinkAccountModal(true)
			} else {
				// Todo: send error to sentry
				navigate('/exception')
			}
		},
	})

	useEffect(() => {
		if (!purchase) {
			return
		}
		setPurchaseResult({
			id: purchase?.ID,
			status: purchase?.Status,
			purchase_date: purchase.PurchaseDate,
			amount: purchase.Amount,
		} as PurchaseResponse)
	}, [purchase, setPurchaseResult])

	return { addPurchase, unhandledError, renderLinkAccountModal, purchase, qrCodeExpired, ...other }
}
