import { useAuth0 } from '@auth0/auth0-react'
import { useMemo } from 'react'

import { MyRole } from '../../../constants/my-role.ts'
import { useBusinessUserByAuthId } from '../../../modules/merchant/hooks/use-business-user-by-auth-id.ts'
import { hasBusinessUserRole } from '../../../utils/role-check.ts'

export const useIsMerchant = () => {
	const { user: auth0User, isLoading: isLoadingAuth } = useAuth0()
	const { data: businessUser, isLoading: isLoadingBusinessUser } = useBusinessUserByAuthId()

	const userRoles: MyRole[] = auth0User?.['paybotic_banking_roles'] || []
	const merchant = hasBusinessUserRole(userRoles)

	return useMemo(() => {
		const isMerchant = merchant && businessUser
		const isLoading = isLoadingAuth || isLoadingBusinessUser
		return { isMerchant, isLoading }
	}, [businessUser, isLoadingAuth, isLoadingBusinessUser, merchant])
}
