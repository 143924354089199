import EditIcon from '@mui/icons-material/Edit'
import { Box, Typography } from '@mui/material'
import { ReactNode, useState } from 'react'
import { FieldErrors, UseFormReturn } from 'react-hook-form'

import { User } from 'src/models/user.models'
import { COLOR } from '../../layout/constants'
import { LinkButton } from '../link-button'
import { EditFieldsModal } from '../modal-components/edit-fields-modal'

export type EditableEdividualDetails = 'Name' | 'Phone' | 'Address'

export type IndividualDetails = EditableEdividualDetails | 'Email'

export type DetailProps =
	| {
			label: 'Email'
			value: string | undefined
			editModalComponent?: null
	  }
	| {
			editModalComponent: ReactNode
			label: EditableEdividualDetails
			value: string | undefined
			onConfirm: () => Promise<UseFormReturn<User, User, User>>
			onCancel: () => void
			onEdit: (modalType: EditableEdividualDetails) => void
			errors: FieldErrors<User>
	  }

export const Detail = (props: DetailProps) => {
	const [openModal, setOpenModal] = useState(false)

	const editable = props.label !== 'Email'

	const onClose = () => setOpenModal(false)

	return (
		<>
			{props.editModalComponent && (
				<EditFieldsModal
					title={props.label}
					open={openModal}
					onCancel={() => {
						onClose()
						props.onCancel()
					}}
					disabled={!!Object.keys(props.errors!).length}
					onConfirm={async () => {
						const { errors } = (await props.onConfirm!()).formState
						if (!Object.keys(errors || {}).length) {
							onClose()
						}
					}}
				>
					{props.editModalComponent}
				</EditFieldsModal>
			)}
			<Box sx={{ color: COLOR.darkPrimary, fontSize: '14px' }}>
				<Typography>
					{props.label}
					{editable && (
						<LinkButton
							handleClick={() => {
								setOpenModal(true)
								props.onEdit(props.label as EditableEdividualDetails)
							}}
							sx={{ minWidth: '40px', paddingBottom: 0.5 }}
						>
							<EditIcon
								sx={{ fontSize: 20 }}
								color="info"
							/>
						</LinkButton>
					)}
				</Typography>
				<Typography
					sx={{
						fontWeight: 'bold',
					}}
				>
					{props.value}
				</Typography>
			</Box>
		</>
	)
}
