import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CreateIcon from '@mui/icons-material/Create'
import { Box, Button, Paper } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { COLOR } from '../../../../layout/constants.ts'
import { useAppStore } from '../../../../store/appStore.ts'
import { formatCurrency } from '../../../../utils/money-utils.ts'
import { Field } from '../../components/Field.tsx'

export function MerchantCheckoutConfirmationPage() {
	const purchase = useAppStore((state) => state.purchase)
	const navigate = useNavigate()

	useEffect(() => {
		if (!purchase) {
			navigate('/merchant/checkout')
		}
	}, [])

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			mt={2}
			sx={{ padding: 2 }}
		>
			<Paper sx={{ p: 4, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}>
				<Field
					label="Total Price"
					value={formatCurrency(purchase?.price ?? 0)}
				/>
				<Field
					label="POS Order ID"
					value={purchase?.posOrderId || '-'}
				/>
				<Field
					label="Notes"
					value={<>{purchase?.notes || '-'}</>}
				/>
			</Paper>
			<Box
				display="flex"
				justifyContent="flex-end"
				mt={2}
				sx={{ width: '100%', maxWidth: 600 }}
			>
				<Button
					onClick={() =>
						navigate('/merchant/checkout', {
							state: {
								isEdit: true,
							},
						})
					}
					type="submit"
					sx={{ width: 120, height: 40, backgroundColor: COLOR.orange, marginRight: 3 }}
					variant="contained"
					size="large"
				>
					EDIT <CreateIcon sx={{ marginLeft: '10px' }} />
				</Button>
				<Button
					onClick={() => navigate('/merchant/checkout/read-qr-code')}
					type="submit"
					sx={{ width: 160, height: 40, backgroundColor: COLOR.orange }}
					variant="contained"
					size="large"
				>
					CHECKOUT <CameraAltIcon sx={{ ml: 1 }} />
				</Button>
			</Box>
		</Box>
	)
}
