import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Box, Divider, Typography } from '@mui/material'

import { Nullable } from '../@types/nullable'
import { User } from '../models/user.models.ts'
import { PurchasePowerResponse } from '../react-query/hooks/use-purchase-power.ts'
import { formatCentsToCurrency } from '../utils/money-utils.ts'
import MyCard from './dashboard-components/my-card.tsx'
import { SpendingLimitInfo } from './spending-limit-info.tsx'

export type PurchasePowerProps = {
	purchasePower: Nullable<PurchasePowerResponse>
	dbUser?: Nullable<User>
	message?: string
}

export function PurchasePower({ purchasePower, dbUser, message }: PurchasePowerProps) {
	const getSmallHeightMediaSx = (fontSizePx: number) => ({
		'@media (max-height: 750px)': {
			fontSize: fontSizePx + 'px',
		},
	})

	return (
		<MyCard>
			<Box
				mb={1}
				data-testid="user-greeting"
				sx={{
					color: 'white',
					fontSize: '14px',
					display: 'flex',
					alignItems: 'center',
					gap: 1,
					...getSmallHeightMediaSx(13),
				}}
			>
				{message ? (
					<Typography
						sx={{
							...getSmallHeightMediaSx(13),
						}}
					>
						{message}
					</Typography>
				) : (
					<>
						<AccountCircleIcon fontSize={'large'} />
						Hello, {dbUser?.first_name} {dbUser?.last_name}!
					</>
				)}
			</Box>
			<Divider sx={{ background: 'white' }} />
			<Box
				data-testid="bank-details"
				component="span"
				display="flex"
				width="fit-content"
				alignItems="center"
				ml="auto"
				mt="1rem"
				mb="1rem"
				gap={1}
				sx={{
					color: 'white',
					fontSize: '13px',
					...getSmallHeightMediaSx(12),
				}}
			>
				<AccountBalanceIcon /> {purchasePower?.account?.vendor_institution_name || 'No account linked'}{' '}
				{purchasePower?.account?.last_four ? `- #${purchasePower?.account?.last_four}` : ''}{' '}
			</Box>
			<Box
				mb="1rem"
				data-testid="bank-balance"
				sx={{
					color: 'white',
					fontSize: '32px',
					...getSmallHeightMediaSx(27),
				}}
			>
				{formatCentsToCurrency(purchasePower?.purchase_power)}
			</Box>
			<SpendingLimitInfo sx={{ ...getSmallHeightMediaSx(12) }} />
		</MyCard>
	)
}
