import { useAuth0 } from '@auth0/auth0-react'

import { MyPermissions } from '../constants/my-permissions.ts'
import { MyRole } from '../constants/my-role.ts'
import { usePermissions } from './use-permissions.ts'

export function useAuthorization(requiredRoles?: MyRole[], requiredPermissions?: MyPermissions[]) {
	const { user } = useAuth0()
	const { permissions, isLoading } = usePermissions()

	const userPermissions = permissions || []
	const userRoles: MyRole[] = user?.['paybotic_banking_roles'] || []

	const hasRequiredRoles = !requiredRoles || requiredRoles.some((requiredRole) => userRoles.includes(requiredRole))
	const hasRequiredPermissions =
		!requiredPermissions ||
		requiredPermissions.length === 0 ||
		requiredPermissions.every((requiredPermission) => userPermissions.includes(requiredPermission))

	return { isAuthorized: hasRequiredRoles && hasRequiredPermissions, isLoading }
}
