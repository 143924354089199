import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { useAlert } from 'src/lib/useAlert.tsx'
import { getKyError } from 'src/utils/fetch-utils.ts'
import { getLogoutOptions } from 'src/utils/logout-utils.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

function deactivateAccount(httpInstance: KyInstance) {
	return httpInstance.delete(`users`).json<string>()
}
export function useDeactivateAccount() {
	const { paymentServiceClient } = usePaymentService()
	const { logout } = useAuth0()
	const { triggerSnackbar } = useAlert()

	return useMutation<string, Error, void, string>({
		mutationFn: async () => {
			return deactivateAccount(paymentServiceClient.httpInstance)
		},
		onSuccess: async () => {
			logout(getLogoutOptions())
		},
		onError: async (error) => {
			console.error(error)
			const errorMessage = await getKyError(error)
			triggerSnackbar(errorMessage || 'Deactivate account failed', 'error')
		},
	})
}
