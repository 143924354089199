import { Box, Button, Skeleton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { AccountCard } from '../components/dashboard-components/account-card.tsx'
import { DashboardLayout } from '../components/dashboard-components/dashboard-layout.tsx'
import { useAccounts } from '../react-query/hooks/use-accounts.ts'

export function ManageAccountsPage() {
	const { data, isLoading } = useAccounts()
	const navigate = useNavigate()

	return (
		<DashboardLayout>
			<Typography
				variant="h4"
				fontWeight="bold"
				sx={{ color: 'white', mb: 4, fontSize: '17px', textAlign: 'center' }}
			>
				Manage Linked Accounts
			</Typography>

			<Box
				display="flex"
				height={'100%'}
				justifyContent="center"
				alignItems="center"
				flexDirection="column"
				gap={2}
			>
				{isLoading ? (
					<Skeleton
						variant="rectangular"
						width={'100%'}
						height={118}
					/>
				) : (
					<>
						{(data || []).map((account) => (
							<AccountCard
								key={account.ID}
								account={account}
							/>
						))}
					</>
				)}
				<Button
					data-testid="add-account"
					variant="contained"
					color="secondary"
					size="large"
					fullWidth={true}
					onClick={() => navigate('/link-account')}
					sx={{
						mt: 'auto',
					}}
				>
					Add Account
				</Button>
			</Box>
		</DashboardLayout>
	)
}
