// TYPESCRIPT CLIENT CODE

export type Address = {
	street_one: string
	street_two?: string
	city: string
	country_code: string
	postal_code: string
	state: string
}

export type User = {
	id: number
	first_name: string
	middle_name: string
	last_name: string
	date_of_birth: string
	dob_day: number
	dob_month: number
	dob_year: number
	email: string
	phone_number: string
	terms_accepted: boolean
	address: Address
	status: 'ACTIVE' | string
	synctera_person_id?: string
	has_created_external_account: boolean
}

export type UserDto = {
	id: number
	first_name: string
	middle_name: string
	last_name: string
	date_of_birth: Date
	email: string
	phone_number: string
	terms_accepted: boolean
	address: Address
	status: 'ACTIVE' | string
}

export type PartialEditUserName = Pick<User, 'first_name' | 'last_name'>
export type PartialEditUserPhone = Pick<User, 'phone_number'>
export type PartialEditUserAddress = {
	address: Pick<User['address'], 'street_one' | 'street_two' | 'postal_code' | 'city' | 'state'>
}

export function toUserDto(user: User): UserDto {
	// Todo: If I destructure this will I keep the dob_day, dob_month, and dob_year?
	// THIS WILL BE SENT TO THE SERVER
	return {
		id: user.id,
		first_name: user.first_name,
		middle_name: user.middle_name,
		last_name: user.last_name,
		date_of_birth: new Date(user.dob_year, user.dob_month - 1, user.dob_day),
		email: user.email,
		phone_number: user.phone_number,
		terms_accepted: user.terms_accepted,
		address: {
			...user.address,
			state: user.address.state.toUpperCase(),
		},
		status: user.status,
	}
}
