import { z } from 'zod'

export const AddressSchema = z.object({
	street_one: z.string().min(1, { message: 'Street required' }),
	street_two: z.string().optional(),
	city: z.string().min(1, { message: 'City required' }),
	state: z.string().length(2, { message: 'State required' }),
	postal_code: z
		.string()
		.min(1, { message: 'Postal code required' })
		.length(5, { message: 'Postal code must be exactly 5 digits' }),
})

export const UserSchema = z.object({
	first_name: z.string().min(2, { message: 'First name is required' }),
	last_name: z.string().min(2, { message: 'Last name is required' }),
	dob_month: z
		.number({ invalid_type_error: 'Month required' })
		.positive('Month required')
		.max(12, { message: 'Month greater than 12' }),
	dob_day: z
		.number({ invalid_type_error: 'Day required' })
		.positive('Day required')
		.max(31, { message: 'Day greater than 31' }),
	dob_year: z
		.number({ invalid_type_error: 'Year required' })
		.min(1900, { message: 'Invalid' })
		.max(new Date().getFullYear(), { message: 'Invalid' }),
	phone_number: z
		.string()
		.min(5, { message: 'Phone number required' })
		.refine(
			(data) => {
				return data.replace(/[^\d]/g, '').length === 10
			},
			{
				message: 'Phone number invalid',
			},
		),
	address: AddressSchema,
	terms_accepted: z.boolean().refine((value) => value, { message: 'You must accept the terms' }),
})

export const StepOneSchema = UserSchema.pick({
	first_name: true,
	last_name: true,
	dob_day: true,
	dob_month: true,
	dob_year: true,
	phone_number: true,
})

export const StepTwoSchema = UserSchema.pick({
	address: true,
})

export const StepThreeSchema = UserSchema.pick({
	terms_accepted: true,
})

export const isNumeric = (value: string) => {
	return /^-?\d+$/.test(value)
}
