import { FormControl, InputLabel, NativeSelect, SxProps, Theme, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form'

import { getNameFromRole, getRoleLabel, MyRole, roleMappings } from 'src/constants/my-role'

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> & {
	error?: FieldError
	disabled?: boolean
	labelEnabled?: boolean
	handleChange?: () => void
	sx?: SxProps<Theme>
}

export const RoleSelect = <TFieldValues extends FieldValues>({
	name,
	control,
	defaultValue,
	disabled,
	labelEnabled = true,
	handleChange,
	error,
	sx,
}: Props<TFieldValues>) => {
	return (
		<FormControl
			fullWidth
			variant="outlined"
			error={!!error}
		>
			{labelEnabled && (
				<InputLabel
					variant="standard"
					htmlFor="uncontrolled-native"
				>
					Role
				</InputLabel>
			)}
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<NativeSelect
						name={name}
						inputProps={{
							name: 'role',
							id: 'uncontrolled-native',
						}}
						placeholder="Select Role"
						className="w-full"
						value={value}
						onChange={(e: ChangeEvent<HTMLSelectElement>) => {
							onChange(e)
							if (handleChange) {
								handleChange()
							}
						}}
						sx={sx}
						error={!!error}
						disabled={disabled}
					>
						<option value=""></option>
						{Array.from(roleMappings.keys())
							.filter((role) => role !== MyRole.Consumer)
							.map((role) => (
								<option
									key={role}
									value={getNameFromRole(role)}
								>
									{getRoleLabel(role)}
								</option>
							))}
					</NativeSelect>
				)}
			/>
			{error && (
				<Typography
					color="error"
					variant="caption"
					marginLeft="14px"
				>
					{error.message}
				</Typography>
			)}
		</FormControl>
	)
}
