import { Component, ErrorInfo, ReactNode } from 'react'

type ErrorBoundaryProps = {
	children: ReactNode
	fallback: (props: ErrorFallbackProps) => ReactNode
	navigate: (path: string) => void
}

type ErrorBoundaryState = {
	hasError: boolean
	error: Error | null
}

type ErrorFallbackProps = {
	error: Error | null
	resetError: () => void
	navigate: (path: string) => void
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	state: ErrorBoundaryState = {
		hasError: false,
		error: null,
	}

	static getDerivedStateFromError(error: Error): ErrorBoundaryState {
		return { hasError: true, error }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo)
	}

	resetError = () => {
		this.setState({ hasError: false, error: null })
	}

	render() {
		if (this.state.hasError) {
			return this.props.fallback({
				error: this.state.error,
				resetError: this.resetError,
				navigate: this.props.navigate,
			})
		}

		return this.props.children
	}
}
