import { Container, Typography } from '@mui/material'
import * as React from 'react'

import { COLOR } from '../../../layout/constants'

type Props = {
	title?: string
	children: React.ReactNode
}

export function PaymentLayout({ children, title }: Props) {
	return (
		<Container
			data-testid="PaymentLayout"
			sx={{ padding: '15px 20px', mt: 4 }}
			style={{ maxWidth: 480 }}
		>
			{title && (
				<Typography
					component="h1"
					sx={{
						fontSize: '18px',
						fontWeight: '600',
						lineHeight: '29.88px',
						textAlign: 'center',
						margin: '10px 0px 15px',
						color: COLOR.white,
					}}
				>
					{title}
				</Typography>
			)}
			{children}
		</Container>
	)
}
