import { useCallback, useEffect, useState } from 'react'

export type AvailableCameras = {
	devices: MediaDeviceInfo[]
	loading: boolean
}

export const useAvailableCameras = () => {
	const [availableCameras, setAvailableCameras] = useState<AvailableCameras>({
		devices: [],
		loading: true,
	})

	const getCameras = useCallback(async (): Promise<AvailableCameras> => {
		const allDevices = await navigator.mediaDevices.enumerateDevices()
		const devices = allDevices.filter((device) => device.kind === 'videoinput')
		return {
			devices,
			loading: false,
		}
	}, [])

	useEffect(() => {
		getCameras().then((cameras) => {
			setAvailableCameras(cameras)
		})
	}, [getCameras])

	return availableCameras
}
