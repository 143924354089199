import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ComponentType } from 'react'

import { MyPermissions } from '../constants/my-permissions.ts'
import { MyRole } from '../constants/my-role.ts'
import { AuthorizationGuard } from './authorization-guard.tsx'
import { Loading } from './Loading'

type Props = {
	component: ComponentType
	requiredRoles?: MyRole[]
	requiredPermissions?: MyPermissions[]
}
export default function AuthenticationGuard({ component, requiredRoles, requiredPermissions }: Props) {
	const Component = withAuthenticationRequired(AuthorizationGuard, {
		onRedirecting: () => <Loading />,
	})
	return (
		<Component
			requiredRoles={requiredRoles}
			requiredPermissions={requiredPermissions}
			Component={component}
		/>
	)
}
