import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useShowNavigation = () => {
	const location = useLocation()

	return useMemo(
		() =>
			!location.pathname.includes('/registration') &&
			!location.pathname.includes('/first-login-tutorial') &&
			!location.pathname.includes('/account-under-review') &&
			!location.pathname.includes('/unauthenticated') &&
			!location.pathname.includes('/merchant'),
		[location.pathname],
	)
}
