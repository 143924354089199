import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { Account } from 'src/@types/accounts'
import { useAlert } from 'src/lib/useAlert.tsx'
import { getKyError } from 'src/utils/fetch-utils.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

async function removeAccount(httpInstance: KyInstance, accountId: string) {
	return await httpInstance
		.delete(`external_accounts`, {
			json: {
				SynAccountID: accountId,
			},
		})
		.json<Account[]>()
}
export function useRemoveExternalAccount() {
	const { paymentServiceClient } = usePaymentService()
	const queryClient = useQueryClient()
	const { triggerSnackbar } = useAlert()

	return useMutation<Account[], Error, string, string>({
		mutationFn: async (accountId: string) => {
			return removeAccount(paymentServiceClient.httpInstance, accountId)
		},
		onSuccess: async (data: Account[]) => {
			queryClient.setQueryData(['accounts'], data)
		},
		onError: async (error) => {
			console.error(error)
			const errorMessage = await getKyError(error)
			triggerSnackbar(errorMessage || 'Remove account failed', 'error')
		},
		onSettled: () => {
			queryClient.resetQueries({ queryKey: ['purchase_power'] })
		},
	})
}
