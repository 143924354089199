import { useEffect, useState } from 'react'

import { ReadQrCode } from '../components/ReadQrcode'

export function TestPage() {
	const [openCamera, setOpenCamera] = useState(false)
	const [result, setResult] = useState('')

	useEffect(() => {
		if (result) {
			setOpenCamera(false)
		}
	}, [result])

	return (
		<div>
			{!openCamera && <button onClick={() => setOpenCamera(!openCamera)}>Open Camera</button>}
			{openCamera && (
				<ReadQrCode
					callback={(data) => {
						setResult(data)
					}}
				/>
			)}
			<br />
			{result && <>RESULT: {result}</>}
		</div>
	)
}
