import { Box, Divider, styled, Typography } from '@mui/material'

import { Transaction as TransactionProps } from '../../react-query/hooks/use-transaction.ts'
import { formatCentsToCurrency } from '../../utils/money-utils'

const Container = styled(Box)({
	display: 'grid',
	gridTemplateColumns: '1fr auto',
	color: 'white',
})

const LeftColumn = styled(Box)({
	display: 'flex',
})

const RightColumn = styled(Box)({
	display: 'flex',
	textAlign: 'end',
	flexDirection: 'column',
	justifyContent: 'end',
	alignItems: 'end',
})

const TransactionTitle = styled(Typography)({
	fontWeight: 'bold',
	fontSize: '14px',
	color: 'white',
})

const LabelText = styled(Typography)({
	fontSize: '11px',
	color: 'white',
})

const LabelValue = styled(Box)({
	display: 'flex',
	alignItems: 'end',
})

const Value = styled(Typography)({
	fontSize: '11px',
	fontWeight: 'bold',
	marginLeft: '5px',
})

const MyDivider = styled(Divider)({
	gridColumn: 'span 2',
	backgroundColor: 'white',
	margin: '10px 0',
})

type Props = TransactionProps & {
	displayDivider?: boolean
}

export function Transaction({
	amount,
	purchase_date,
	public_id,
	business_name,
	last_four,
	status,
	displayDivider = true,
}: Props) {
	return (
		<Container>
			<LeftColumn>
				<TransactionTitle data-testid="transaction-business-name">{business_name}</TransactionTitle>
			</LeftColumn>
			<RightColumn>
				<TransactionTitle data-testid="transaction-amount">{formatCentsToCurrency(amount)}</TransactionTitle>
				<LabelValue>
					<LabelText>Account No.</LabelText>
					<Value data-testid="transaction-account-no">x{last_four}</Value>
				</LabelValue>
				<LabelValue>
					<LabelText>Transaction ID.</LabelText>
					<Value data-testid="transaction-id">{public_id}</Value>
				</LabelValue>
				<LabelValue>
					<LabelText>Status.</LabelText>
					<Value data-testid="transaction-status">{status}</Value>
				</LabelValue>
				<LabelValue>
					<Value data-testid="transaction-purchase-date">{new Date(purchase_date).toDateString()}</Value>
				</LabelValue>
			</RightColumn>
			{displayDivider && <MyDivider />}
		</Container>
	)
}
