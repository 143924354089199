import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

import { getLogoutOptions } from 'src/utils/logout-utils'

export function LogoutPage() {
	const { logout } = useAuth0()
	useEffect(() => {
		logout(getLogoutOptions())
			.then(() => console.log('Logged out'))
			.catch((error) => console.error('Logout error', error))
	}, [logout])
	return <></>
}
