import { Box, Typography } from '@mui/material'

export const Field = ({ label, value }: { label: string; value: string | JSX.Element }) => (
	<Box sx={{ marginBottom: '20px' }}>
		<Typography
			component="span"
			variant="body1"
			sx={{
				fontSize: '14px',
				fontWeight: '400',
				lineHeight: '19.88px',
				color: '#244D74',
			}}
		>
			<Typography
				component="span"
				variant="body1"
				sx={{
					fontSize: '14px',
					fontWeight: '600',
					lineHeight: '19.88px',
				}}
			>
				{label}:
			</Typography>{' '}
			{value}
		</Typography>
	</Box>
)
