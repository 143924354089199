import Card from '@mui/material/Card'
import * as React from 'react'

import { COLOR } from '../../layout/constants.ts'

type Props = {
	children: React.ReactNode
	variant?: 'primary' | 'secondary'
	sx?: object
} & React.ComponentPropsWithoutRef<'div'>

export default function MyCard({ children, variant = 'primary', sx = {}, ...rest }: Props) {
	return (
		<Card
			sx={{
				background: variant === 'primary' ? COLOR.darkBlue : 'white',
				padding: '1rem',
				borderRadius: '16px',
				overflow: 'initial',
				...sx,
			}}
			{...rest}
		>
			{children}
		</Card>
	)
}
