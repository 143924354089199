import { useAuth0 } from '@auth0/auth0-react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, styled } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction, { BottomNavigationActionProps } from '@mui/material/BottomNavigationAction'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const NavigationButton = styled(BottomNavigationAction)<BottomNavigationActionProps>(() => ({
	color: 'white',
	borderBottom: '3px solid transparent',
	'&.Mui-selected': {
		color: 'white',
		borderBottom: '3px solid #FF9100',
	},
}))

type NavigationConfig = {
	label: string
	value: string
	icon: React.ReactElement
}

type NavigationProps = {
	userType: 'consumer' | 'merchant'
}

const navigationConfigs: Record<string, NavigationConfig[]> = {
	consumer: [
		{ label: 'Home', value: '/dashboard', icon: <HomeIcon /> },
		{ label: 'Profile', value: '/dashboard/profile', icon: <AccountCircleIcon /> },
		{ label: 'Settings', value: '/dashboard/settings', icon: <SettingsIcon /> },
	],
}

export function Navigation({ userType }: NavigationProps) {
	const navigate = useNavigate()
	const location = useLocation()
	const { user } = useAuth0()

	const [value, setValue] = React.useState(location.pathname || 'dashboard')
	const currentConfig = navigationConfigs[userType] || []

	if (!user?.email_verified || !currentConfig || currentConfig.length === 0) {
		return null
	}

	return (
		<Box
			component={'footer'}
			sx={{
				position: 'fixed',
				bottom: 0,
				width: '100%',
				zIndex: 6,
				left: 0,
			}}
		>
			<BottomNavigation
				showLabels
				sx={{
					background: '#244D74',
					height: '78px',
					'@media (max-height: 750px)': {
						height: '70px',
					},
				}}
				value={value}
				onChange={(_event: React.SyntheticEvent<Element, Event>, newValue) => {
					setValue(newValue)
					navigate(newValue)
				}}
			>
				{currentConfig.map((item) => (
					<NavigationButton
						key={item.value}
						label={item.label}
						value={item.value}
						icon={item.icon}
					/>
				))}
			</BottomNavigation>
		</Box>
	)
}
