import { Autocomplete, TextField } from '@mui/material'
import { InputHTMLAttributes } from 'react'
import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form'

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> & {
	label: string
	error?: FieldError
	disabled?: boolean
	inputProps?: InputHTMLAttributes<HTMLInputElement>
	options: { value: string; label: string }[]
}
export function MyAutocompleteField<TFieldValues extends FieldValues>({
	name,
	control,
	label,
	rules,
	disabled,
	defaultValue,
	options,
}: Props<TFieldValues>) {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<Autocomplete
					value={options.find((option) => option.value === value) || null}
					size="small"
					sx={{ mt: 1, pb: '3px' }}
					disabled={disabled}
					options={options}
					getOptionLabel={(option) => option?.label}
					onChange={(_, data) => onChange(data?.value || '')}
					renderInput={(params) => (
						<TextField
							{...params}
							name={name}
							label={label}
							fullWidth
							error={!!error}
							helperText={error?.message}
							inputProps={{ ...params.inputProps }}
						/>
					)}
				/>
			)}
			rules={rules}
		/>
	)
}
