import { useAuth0 } from '@auth0/auth0-react'
import { Navigate } from 'react-router-dom'

import { Loading } from '../../../components/Loading'
import { MyRole } from '../../../constants/my-role.ts'
import { usePermissions } from '../../../hooks/use-permissions.ts'
import { useUser } from '../../../react-query/hooks/use-user.ts'
import { hasBusinessUserRole } from '../../../utils/role-check.ts'
import { useBusinessUserByAuthId } from '../../merchant/hooks/use-business-user-by-auth-id.ts'

export default function RedirectPage() {
	const { user: auth0User } = useAuth0()
	const { data: dbUser, isLoading: queryRunning } = useUser()
	const { data: businessUser, isLoading: fetchingBusinessUser } = useBusinessUserByAuthId()
	const { paymentCenterEnabled, isLoading: permissionsRunning } = usePermissions()

	if (queryRunning || permissionsRunning || fetchingBusinessUser) {
		return <Loading />
	}

	const userRoles: MyRole[] = auth0User?.['paybotic_banking_roles'] || []
	const bu = hasBusinessUserRole(userRoles)
	if (paymentCenterEnabled === false) {
		return <Navigate to="/unauthenticated" />
	}
	if (bu && businessUser) {
		return <Navigate to="/merchant" />
	}
	if (bu && !businessUser) {
		return <Navigate to="/merchant/registration" />
	}

	if (!dbUser) {
		return <Navigate to="/registration" />
	}

	if (!auth0User?.email_verified) {
		return <Navigate to="/email-verification" />
	}

	if (!dbUser.has_created_external_account) {
		return <Navigate to="/first-login-tutorial" />
	}

	return <Navigate to="/dashboard" />
}
