import { Box } from '@mui/material'
import { useCallback } from 'react'

import AlertSmall from '../assets/images/error/alert-small.svg'
import { ModalError } from '../components/error/ModalError'

export const AlertErrorPage = () => {
	const onClick = useCallback(() => {
		window.location.reload()
	}, [])

	return (
		<Box data-testid="ErrorPage">
			<ModalError
				title={<>We've Encountered An Issue</>}
				image={AlertSmall}
				buttom={{ text: 'Refresh', onClick }}
			/>
		</Box>
	)
}
