import { Paper } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { useDashboardReport } from '../../../react-query/hooks/use-dashboard-report.ts'
import { dayjs } from '../../../utils/dates.ts'
import { formatCentsToCurrency } from '../../../utils/money-utils.ts'
import { DataTable } from './Table.tsx'

export function MerchantHomeReport() {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = parseInt(searchParams.get('page') || '0')
	const pageSize = parseInt(searchParams.get('pageSize') || '10')

	const { data: reportData, isLoading: reportLoading } = useDashboardReport({
		limit: pageSize,
		offset: page * pageSize,
	})

	const handlePaginationChange = (newPage: number, newPageSize: number) => {
		setSearchParams({ page: newPage.toString(), pageSize: newPageSize.toString() })
	}

	return (
		<Paper sx={{ p: 2, mt: 2 }}>
			<DataTable
				loading={reportLoading}
				data={reportData?.items || []}
				columns={[
					{ label: 'Store name', dataIndex: 'StoreName' },
					{ label: 'Customer Name', dataIndex: 'CustomerName' },
					{
						label: 'Transaction Time',
						dataIndex: 'DateTimeCreated',
						render: (value) => dayjs(value).tz('US/Central').format('MM/DD/YYYY HH:mm:ss z'),
					},
					{
						label: 'Status',
						dataIndex: 'TransactionStatus',
					},
					{
						label: 'Sales Amount',
						dataIndex: 'BaseAmount',
						align: 'right',
						render: (value) => formatCentsToCurrency(value as number),
					},
				]}
				pagination={{
					page,
					pageSize,
					total: reportData?.page_info.total || 0,
					hasMore: reportData?.page_info.has_more || false,
					onChangePagination: handlePaginationChange,
				}}
			/>
		</Paper>
	)
}
