import { MyRole } from '../../../constants/my-role.ts'

export type Auth0Role = {
	id: string
	name: MyRole
	description: string
}

export type BusinessUser = {
	ID: number
	BusinessID: number
	Auth0ID: string
	SynPersonID: string
	Email: string
	Username: string
	Roles: Auth0Role[]
	CreatedAt: Date
	UpdatedAt: Date
	BusinessName: string
}

export const isUserOrCreatedBy = ({
	auth0ID,
	businessUserAuth0Id,
	businessUserCreatedBy,
}: {
	auth0ID: string
	businessUserAuth0Id: string
	businessUserCreatedBy: string | undefined
}) => businessUserAuth0Id === auth0ID || businessUserCreatedBy === auth0ID
