import { Box, styled } from '@mui/material'
import { ReactNode } from 'react'

import { Header } from './component/Header.tsx'
import { use100vh } from './utils/use100vh.ts'
import { useBackgroundImage } from './utils/useBackgroundImage.ts'
import { useShowHeader } from './utils/useShowHeader.ts'

const Background = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	overflowY: 'auto',
	position: 'relative',
	width: '100%',
	background: `linear-gradient(#6C9ECD, #092845)`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	'&::before': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.1)', // Dark overlay, adjust as needed
		backdropFilter: 'blur(10px)', // Blur effect
	},
})

type Props = {
	children: ReactNode
}
export function UnauthenticatedLayout({ children }: Props) {
	// Todo: Add loading check to prevent background flicker

	const backgroundImage = useBackgroundImage()
	const showHeader = useShowHeader()
	const height = use100vh()

	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
			}}
		>
			<Background
				sx={{
					height: height,
					backgroundImage: `url("${backgroundImage}")`,
				}}
			>
				{showHeader && <Header />}
				<Box
					component={'main'}
					display="flex"
					flexDirection="column"
					height={'100%'}
					sx={{
						marginBottom: 0,
						zIndex: 1,
					}}
				>
					{children}
				</Box>
			</Background>
		</Box>
	)
}
