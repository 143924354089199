import { Box, Button, Card, Modal, Typography } from '@mui/material'

import { COLOR } from '../../layout/constants'

type Props = {
	title: string | JSX.Element
	image: string
	buttom: { text: string; onClick: () => void }
}
export const ModalError = ({ title, image, buttom }: Props) => {
	return (
		<Modal open={true}>
			<Card
				sx={{
					background: 'white',
					display: 'grid',
					p: 4,
					borderRadius: 4,
					width: '90vw',
					margin: 'calc(50vh - 150px) auto 0',
				}}
			>
				<Typography
					sx={{
						fontSize: '20px',
						fontWeight: '700',
						lineHeight: '25px',
						textAlign: 'center',
						color: COLOR.errorLight,
					}}
				>
					{title}
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center', margin: '15px 0px' }}>
					<img src={image} />
				</Box>

				<Button
					variant="contained"
					color="inherit"
					sx={{ textTransform: 'uppercase', width: '100%', padding: '10px 0', color: COLOR.errorLight }}
					onClick={buttom.onClick}
				>
					{buttom.text}
				</Button>
			</Card>
		</Modal>
	)
}
