import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { ReactNode } from 'react'

type CustomProps = {
	children: ReactNode
	onClick?: () => void
	type?: 'submit' | 'button' | 'reset'
	disabled?: boolean
	loading?: boolean
}

type Props = CustomProps & Omit<ButtonProps, keyof CustomProps>

export function BtnLg({ children, onClick, type = 'button', disabled, loading, sx = {}, ...rest }: Props) {
	return (
		<Button
			onClick={onClick}
			type={type}
			size={'large'}
			variant={'contained'}
			color={'secondary'}
			fullWidth={true}
			sx={{
				height: '56px',
				...sx,
			}}
			disabled={disabled || loading}
			{...rest}
		>
			{loading ? (
				<CircularProgress
					size={24}
					color="inherit"
				/>
			) : (
				children
			)}
		</Button>
	)
}
