import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { ReactNode } from 'react'

export function EditFieldsModal({
	title,
	open,
	onConfirm,
	onCancel,
	children,
	disabled,
}: {
	open: boolean
	title: string
	onCancel: () => void
	onConfirm: (data: unknown) => void
	children: ReactNode
	disabled: boolean
}) {
	return (
		<Dialog
			open={open}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
			<DialogContent dividers={true}>{children}</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>Cancel</Button>
				<Button
					color={'info'}
					variant={'contained'}
					onClick={() => onConfirm({})}
					disabled={disabled}
					autoFocus
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}
