import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useRef } from 'react'

import { PurchaseWebsocketStatus } from '../../../@types/purchase'
import { stripStringOf } from '../../../utils/string-mods.ts'

export const usePurchaseWebSocket = (
	purchaseId: string | undefined,
	onUpdate: (purchaseWebsocketStatus: PurchaseWebsocketStatus) => void,
) => {
	const { getAccessTokenSilently } = useAuth0()

	const baseURL = stripStringOf(import.meta.env.VITE_PAYMENT_SERVICE_API_URL, ['http://', 'https://'])
	const wsRef = useRef<WebSocket | null>(null)

	useEffect(() => {
		if (!purchaseId) return

		const connectWebsocket = async () => {
			try {
				const accessToken = await getAccessTokenSilently()
				const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
				const wsURL = `${protocol}://${baseURL}/ws/purchases/${purchaseId}?token=${accessToken}`

				wsRef.current = new WebSocket(wsURL)

				wsRef.current.onopen = () => {
					console.log('Connected to WebSocket')
				}
				wsRef.current.onmessage = (event) => {
					const purchaseWebsocketStatus = JSON.parse(event.data)
					onUpdate(purchaseWebsocketStatus as PurchaseWebsocketStatus)
				}
				wsRef.current.onclose = () => console.log('WebSocket connection closed')
				wsRef.current.onerror = (error) => {
					console.error('WebSocket error:', error)
				}
			} catch (e) {
				console.error(e)
			}
		}

		connectWebsocket()

		return () => wsRef.current?.close()
	}, [purchaseId, onUpdate, getAccessTokenSilently, baseURL])
}
