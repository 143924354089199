import { Box, Button, Modal, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export function LinkAccountModal() {
	const navigate = useNavigate()

	return (
		<Modal open={true}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				gap={2}
				sx={{
					backgroundColor: 'white',
					borderRadius: '8px',
					padding: '16px',
					height: '200px',
					width: '95vw',
					maxWidth: '350px',
					marginX: 'auto',
					marginTop: '20vh',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant="h6"
					sx={{ color: 'black' }}
				>
					You need to link an account in order to proceed
				</Typography>
				<Button
					onClick={() => {
						navigate('/link-account')
					}}
					variant="contained"
					color="primary"
				>
					Link Account
				</Button>
			</Box>
		</Modal>
	)
}
