import { motion } from 'framer-motion'
import { ReactNode } from 'react'

type Props = {
	children: ReactNode
}

const fadeVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 },
}

export const Effect01 = ({ children }: Props) => {
	return (
		<motion.div
			variants={fadeVariants}
			initial="hidden"
			animate="visible"
			transition={{ duration: 2, ease: 'easeInOut' }}
		>
			{children}
		</motion.div>
	)
}
