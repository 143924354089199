import { Box, Button, Skeleton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAccounts } from 'src/react-query/hooks/use-accounts.ts'
import { DashboardLayout } from '../components/dashboard-components/dashboard-layout.tsx'
import { LinkAccountModalToken } from '../components/link-account-modal-token.tsx'
import { LinkAccountModal } from '../components/modal-components/link-account-modal.tsx'
import { PurchasePower } from '../components/purchase-power.tsx'
import { useAlert } from '../lib/useAlert.tsx'
import { usePurchasePower } from '../react-query/hooks/use-purchase-power.ts'
import { useUser } from '../react-query/hooks/use-user.ts'

export function DashboardPage() {
	const [showLinkAccountModal, setShowLinkAccountModal] = useState(false)
	const { data: dbUser, isLoading: queryRunning } = useUser()
	const { data: accounts, isLoading: accountsLoading } = useAccounts()
	const { data: purchasePower, isLoading, error } = usePurchasePower()
	const { triggerSnackbar } = useAlert()
	const navigate = useNavigate()

	let renderLinkAccountModal = true
	if (accounts) {
		renderLinkAccountModal = accounts.findIndex((a) => a.IsPrimary) === -1
	}

	useEffect(() => {
		if (error && error.message === 'ITEM_LOGIN_REQUIRED') {
			setShowLinkAccountModal(true)
		}
		if (error && error.message !== 'ITEM_LOGIN_REQUIRED') {
			triggerSnackbar('Failed to fetch purchase power')
		}
	}, [error, triggerSnackbar])

	const handlePayNow = () => {
		if (accounts?.length) {
			navigate('/payment')
		} else {
			navigate('/link-account?redirectTo=/payment')
		}
	}

	return (
		<DashboardLayout>
			{!accountsLoading && renderLinkAccountModal && <LinkAccountModal />}
			<Box
				gap={4}
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'space-between'}
			>
				<Box>
					<Typography
						variant="h4"
						fontWeight="bold"
						sx={{
							color: 'white',
							mb: 2,
							fontSize: '17px',
							textAlign: 'center',
						}}
					>
						Home
					</Typography>
					{accountsLoading || isLoading || queryRunning ? (
						<Skeleton
							variant="rounded"
							width={'100%'}
							height={'236px'}
						/>
					) : (
						<PurchasePower
							purchasePower={purchasePower}
							dbUser={dbUser}
						/>
					)}
				</Box>
				<Button
					data-testid="pay-now"
					onClick={handlePayNow}
					variant="contained"
					color="secondary"
					size="large"
					fullWidth={true}
				>
					PAY NOW
				</Button>
			</Box>
			{showLinkAccountModal && <LinkAccountModalToken />}
		</DashboardLayout>
	)
}
