import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'

type ConfirmationModalProps = {
	open: boolean
	handleClose: () => void
	handleAction: () => void
	text: string
}

export function ConfirmationModal({ open, handleClose, handleAction, text }: ConfirmationModalProps) {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>No</Button>
				<Button
					color={'error'}
					variant={'outlined'}
					onClick={handleAction}
					autoFocus
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	)
}
