export function formatCurrency(value: number | null | undefined): string {
	if (!value) {
		value = 0
	}

	return value.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	})
}

export function formatCentsToCurrency(value: number | null | undefined): string {
	if (!value) {
		value = 0
	} else {
		value = value / 100
	}

	return formatCurrency(value)
}

export function convertToCents(value: number): number {
	if (value === null || value === undefined) {
		return 0
	}
	const fixedValue = Number((value * 100).toFixed(2))
	return fixedValue || 0
}
