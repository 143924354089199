import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { MediaDeviceInfo } from './qr-types.ts'
import { CameraOption } from './ReadQrcode.tsx'

type SetDeviceFunction = React.Dispatch<React.SetStateAction<MediaDeviceInfo | undefined>>

type CameraSelectorProps = {
	options: CameraOption[]
	selectedOption: string
	isMobile: boolean
	setFacingMode: (value: string) => void
	setDevice: SetDeviceFunction
	availableDevices: MediaDeviceInfo[]
}

export const CameraSelector: React.FC<CameraSelectorProps> = ({
	options,
	selectedOption,
	isMobile,
	setFacingMode,
	setDevice,
	availableDevices,
}) => {
	const handleChange = (event: SelectChangeEvent) => {
		if (isMobile) {
			setFacingMode(event.target.value)
		} else {
			const selected = availableDevices.find((d) => d.deviceId === event.target.value)
			if (selected) setDevice(selected)
		}
	}

	return (
		<FormControl sx={{ marginBottom: '10px', width: '100%' }}>
			<InputLabel id="choose-camera">Choose Camera</InputLabel>
			<Select
				labelId="choose-camera"
				id="choose-camera-select"
				value={selectedOption}
				label="name"
				onChange={handleChange}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{options.map((option) => (
					<MenuItem
						key={option.value}
						value={option.value}
					>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
