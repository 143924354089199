import { Box, Button, Card, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Animation } from '../components/animation'
import { DashboardLayout } from '../components/dashboard-components/dashboard-layout.tsx'
import { COLOR } from '../layout/constants.ts'
import Success from '../modules/payment/assets/success.svg'

export function LinkAccountSuccessPage() {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	return (
		<DashboardLayout
			sx={{
				height: '100%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					mt: '90px',
				}}
				data-testid="LinkAccountSuccessPage"
			>
				<Card
					sx={{
						background: 'white',
						display: 'grid',
						px: '24px',
						py: '42px',
						maxWidth: 600,
						width: '100%',
						mx: 'auto',
						borderRadius: 4,
					}}
				>
					<div
						style={{
							margin: 'auto',
							marginBottom: '12px',
							height: 96,
							width: 96,
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Animation.Effect02>
							<img
								style={{
									width: 80,
									height: 80,
								}}
								src={Success}
								alt={'success'}
							/>
						</Animation.Effect02>
					</div>
					<Typography
						component="h1"
						sx={{
							fontSize: '24px',
							fontWeight: '700',
							lineHeight: '29px',
							textAlign: 'center',
							color: COLOR.successText,
							mb: '18px',
						}}
					>
						SUCCESS
					</Typography>
					<Typography
						component="p"
						sx={{
							fontSize: '13px',
							fontWeight: '400',
							lineHeight: '15px',
							textAlign: 'center',
							mb: '22px',
						}}
					>
						Your bank account is linked
					</Typography>
					<Button
						variant="contained"
						size="large"
						color={'primary'}
						onClick={() => {
							const redirectTo = searchParams.get('redirectTo')
							if (redirectTo) {
								navigate(redirectTo)
							} else {
								navigate('/dashboard')
							}
						}}
					>
						CONTINUE
					</Button>
				</Card>
			</Box>
		</DashboardLayout>
	)
}
