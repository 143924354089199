import { ChangeEvent } from 'react'

export function handlePhoneNumberChange(
	e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	onChange: (value: string) => void,
) {
	const value = e.target.value
	// Remove all non-digit characters
	const numbers = value.replace(/\D/g, '')

	// Apply formatting
	let phoneNumber = ''
	for (let i = 0; i < numbers.length && i < 10; i++) {
		switch (i) {
			case 0:
				phoneNumber += '(' + numbers[i]
				break
			case 2:
				phoneNumber += numbers[i] + ') '
				break
			case 5:
				phoneNumber += numbers[i] + '-'
				break
			default:
				phoneNumber += numbers[i]
		}
	}

	// Update the input value using the provided onChange callback
	onChange(phoneNumber)
}
