import { useMutation } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { ConsumerSupport, Support } from '../../@types/support'
import { useAlert } from '../../lib/useAlert.tsx'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

async function createSupport(httpInstance: KyInstance, body: Support | ConsumerSupport) {
	return httpInstance.post('support', { json: body }).json<Support>()
}
export function useSupport(onSuccess: () => void) {
	const { triggerSnackbar } = useAlert()
	const { paymentServiceClient } = usePaymentService()

	return useMutation({
		mutationFn: async (body: Support | ConsumerSupport) => {
			return createSupport(paymentServiceClient.httpInstance, body)
		},
		onSuccess: async () => {
			triggerSnackbar('Support request sent', 'success')
			onSuccess()
		},
		onError: () => {
			triggerSnackbar('Something went wrong creating support request')
		},
	})
}
