import { useMutation } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'

import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { BodyRequest } from './use-report.ts'

async function generateReport(httpInstance: KyInstance, body: BodyRequest) {
	try {
		const response = await httpInstance.post('businesses/create-report', {
			body: JSON.stringify({
				start_date: body?.start_date || '',
				end_date: body?.end_date || '',
				store_id: body?.store_id || '',
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return await response.blob()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}
export function useGenerateReport() {
	const { paymentServiceClient } = usePaymentService()
	return useMutation({
		mutationFn: async (body: BodyRequest) => {
			return generateReport(paymentServiceClient.httpInstance, body)
		},
		// Todo: Should the be onMutate
		onSuccess: async (blob: Blob | null) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url
				a.download = 'report.csv'
				document.body.appendChild(a)
				a.click()
				window.URL.revokeObjectURL(url)
				document.body.removeChild(a)
			} else {
				console.log('No report available')
			}
		},
		onError: (error) => {
			// Todo: Notify the user of the error
			console.error(error)
		},
	})
}
