import { useAuth0 } from '@auth0/auth0-react'
import { Route, Routes } from 'react-router-dom'

import AuthenticationGuard from '../../components/authentication-guard.tsx'
import { Loading } from '../../components/Loading'
import { MyPermissions } from '../../constants/my-permissions.ts'
import { CreateBusinessUsersPage } from './pages/create-business-users.page.tsx'
import { ListBusinessUsersPage } from './pages/list-business-users.page.tsx'

export const BusinessUsersRouters = () => {
	const { isLoading, error } = useAuth0()
	if (isLoading) {
		return <Loading />
	}
	if (error) {
		return <div>Oops... {error.message}</div>
	}
	return (
		<Routes>
			<Route
				path="/"
				element={
					<AuthenticationGuard
						component={ListBusinessUsersPage}
						requiredPermissions={[MyPermissions.ReadBusinessUsers]}
					/>
				}
			/>
			<Route
				path="/create"
				element={
					<AuthenticationGuard
						component={CreateBusinessUsersPage}
						requiredPermissions={[MyPermissions.CreateBusinessUsers]}
					/>
				}
			/>
		</Routes>
	)
}
