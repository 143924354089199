import { Box, BoxProps } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
	children: ReactNode
	sx?: BoxProps['sx']
}
export function ContentCard({ children, sx = {} }: Props) {
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				borderRadius: '10px',
				padding: '35px 30px 40px',
				boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
				'&:hover': {
					boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
				},
				...sx,
			}}
		>
			{children}
		</Box>
	)
}
