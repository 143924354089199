import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

export const LoginPage = () => {
	const { loginWithRedirect } = useAuth0()

	useEffect(() => {
		loginWithRedirect()
			.then(() => console.log('login'))
			.catch((error) => console.error('login error', error))
	}, [loginWithRedirect])

	return <></>
}
