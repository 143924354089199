import MenuIcon from '@mui/icons-material/Menu'
import { Avatar, Box, IconButton, styled, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import LogoBlue from '../../../assets/images/logo-blue.svg'
import { COLOR } from '../../../layout/constants.ts'
import { useAppStore } from '../../../store/appStore.ts'
import { useBusinessUserByAuthId } from '../hooks/use-business-user-by-auth-id.ts'

const StyledHeader = styled(Box)(({ theme }) => ({
	backgroundColor: COLOR.white,
	paddingLeft: theme.spacing(6),
	paddingRight: theme.spacing(6),
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: 100,
	'& h1': {
		fontSize: 30,
		fontWeight: 700,
		color: COLOR.primary,
	},
	'@media (max-width: 1280px)': {
		height: 75,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}))

export function Header() {
	const location = useLocation()
	const setOpenMerchantSidebar = useAppStore((state) => state.setOpenMerchantSidebar)
	const { data: businessUser, isLoading: fetchingBusinessUser } = useBusinessUserByAuthId()
	const loggedAs = businessUser?.BusinessName

	const handleOpenMerchantSidebar = () => {
		setOpenMerchantSidebar(true)
	}

	const title = useMemo(() => {
		const pathname = location.pathname
		if (pathname.includes('/merchant/tips')) {
			return 'Tips'
		}
		if (pathname.includes('/merchant/checkout')) {
			return 'Checkout'
		}
		if (pathname.includes('/merchant/report')) {
			return 'Reports'
		}
		if (pathname.includes('/merchant/support')) {
			return 'Support'
		}
		if (pathname.includes('/merchant/checkout/confirmation')) {
			return 'Checkout Confirmation'
		}
		if (pathname.includes('/merchant/profile')) {
			return 'Profile'
		}
		if (pathname.includes('/businesses/users')) {
			return 'Users'
		}
		return 'Home'
	}, [location.pathname])

	return (
		<StyledHeader component="nav">
			<Box
				sx={{
					'@media (min-width: 1281px)': {
						display: 'none',
					},
					display: {
						xs: 'flex',
						sm: 'flex',
						md: 'flex',
						lg: 'flex',
						justifyContent: 'center',
						width: '100%',
					},
				}}
			>
				<Box
					sx={{
						position: 'relative',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						display: 'flex',
						'@media (min-width: 1281px)': {
							display: 'none',
						},
					}}
				>
					<IconButton
						onClick={handleOpenMerchantSidebar}
						sx={{
							position: 'absolute',
							left: 0,
						}}
						aria-label="menu"
					>
						<MenuIcon sx={{ color: COLOR.successText }} />
					</IconButton>
					<Box
						sx={{
							'@media (min-width: 1281px)': {
								display: 'none',
							},
						}}
					>
						<img
							style={{ height: 35 }}
							src={LogoBlue}
							alt="phone logo"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: {
						justifyContent: 'space-between',
						width: '100%',
						display: 'flex',
					},
					'@media (max-width: 1280px)': {
						display: 'none',
					},
				}}
			>
				{title && <Typography component="h1">{title}</Typography>}
				<Box
					display={'flex'}
					alignItems={'center'}
					gap={2}
				>
					{!fetchingBusinessUser ? (
						<Box
							display={'flex'}
							flexDirection={'column'}
							alignItems={'flex-end'}
						>
							<Typography>Hi, {businessUser?.Email}</Typography>
							{loggedAs && <Typography data-testid="logged-as-store">Store: {loggedAs}</Typography>}
						</Box>
					) : null}
					<Avatar>{businessUser?.Email.slice(0, 1)}</Avatar>
				</Box>
			</Box>
		</StyledHeader>
	)
}
