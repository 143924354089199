import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'

import { BtnLg } from './btn-lg.tsx'

export function SignUpBtn() {
	const { loginWithRedirect } = useAuth0()
	return (
		<Box
			sx={{
				padding: '50px 0 30px',
				width: '100%',
			}}
		>
			<BtnLg
				onClick={async () => {
					await loginWithRedirect({
						authorizationParams: {
							screen_hint: 'signup',
						},
					})
				}}
			>
				GET STARTED
			</BtnLg>
		</Box>
	)
}
