import { TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import { isNumeric } from 'src/utils/validations.tsx'
import { PartialEditUserPhone } from '../../models/user.models.ts'
import { handlePhoneNumberChange } from '../form-components/handle-phone-number-change.ts'

export function PhoneTextField() {
	const {
		control,
		formState: { errors },
		getValues,
		setValue,
		handleSubmit,
	} = useFormContext<PartialEditUserPhone>()

	const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
		if (['Backspace'].includes(e.key)) {
			const phoneNumber = getValues('phone_number')
			let numberOfDigitsToDelete = 0
			while (numberOfDigitsToDelete < phoneNumber.length) {
				numberOfDigitsToDelete++
				const start = phoneNumber.length - numberOfDigitsToDelete - 1
				const currentChar = phoneNumber.substring(start, start + 1)
				if (isNumeric(currentChar)) {
					break
				}
			}
			setValue('phone_number', phoneNumber.substring(0, phoneNumber.length - numberOfDigitsToDelete))
			handleSubmit(() => {})() // force render errors
			e.preventDefault()
		}
	}

	return (
		<Controller
			name="phone_number"
			control={control}
			rules={{ required: 'Phone required' }}
			render={({ field: { onChange, value } }) => (
				<TextField
					value={value}
					error={!!errors.phone_number}
					onChange={(e) => handlePhoneNumberChange(e, onChange)}
					onKeyDown={handleKeyDown}
					fullWidth
					label="Phone Number"
					margin="dense"
					size="small"
					type="tel"
					helperText={errors.phone_number?.message}
					placeholder="(123) 456-7890"
				/>
			)}
		/>
	)
}
