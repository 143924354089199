import { zodResolver } from '@hookform/resolvers/zod'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { Box, Button, Grid, Paper, TextField } from '@mui/material'
import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { PurchaseInfo } from '../../../../@types/purchase'
import { MyTextField } from '../../../../components/form-components/my-text-field.tsx'
import { Loading } from '../../../../components/Loading'
import { COLOR } from '../../../../layout/constants.ts'
import { useAppStore } from '../../../../store/appStore.ts'
import { useBusinessUserByAuthId } from '../../hooks/use-business-user-by-auth-id.ts'

const formSchema = z.object({
	storeName: z.string().min(1, { message: 'Store Name is required' }),
	price: z.number().min(0.01, { message: 'Price must be at least $0.01' }),
	employeeName: z.string().min(1, { message: 'Employee Name is required' }),
	posOrderId: z.string().optional(),
	notes: z.string().optional(),
} as Record<keyof PurchaseInfo, z.ZodOptional<z.ZodString> | z.ZodString | z.ZodNumber>)

const defaultValues = {
	storeName: '',
	price: 0,
	employeeName: '',
	posOrderId: '',
	notes: '',
}

export function MerchantCheckoutPage() {
	const navigate = useNavigate()
	const { state } = useLocation()
	const { data: businessUser, isLoading } = useBusinessUserByAuthId()
	const setPurchase = useAppStore((state) => state.setPurchase)
	const purchase = useAppStore((state) => state.purchase) as PurchaseInfo | null

	const methods = useForm({
		defaultValues,
		resolver: zodResolver(formSchema),
	})
	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = methods

	useEffect(() => {
		if (state?.isEdit && purchase) {
			for (const key of Object.keys(purchase) as ('price' | 'posOrderId' | 'notes' | 'storeName' | 'employeeName')[]) {
				setValue(key, purchase[key])
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setValue('storeName', businessUser?.BusinessName as string)
	}, [setValue, businessUser?.BusinessName])

	const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const input = event.target.value.replace(/\D/g, '') // Remove all non-digit characters
		const num = input ? parseFloat(input) / 100 : 0 // Convert to dollars
		setValue('price', num) // Update the form state
	}

	const onSubmit = (data: PurchaseInfo) => {
		setPurchase(data)
		navigate('/merchant/checkout/confirmation')
	}

	if (isLoading) {
		return <Loading />
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			mt={2}
			sx={{ padding: 2 }}
		>
			<Paper sx={{ p: 4, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid
							item
							xs={12}
							md={8}
						>
							<Box>
								<Grid
									container
									spacing={{
										xs: 0,
										md: 2,
									}}
								>
									<Grid
										item
										xs={12}
										md={6}
									>
										<MyTextField
											name="storeName"
											label="Store Name"
											error={errors.storeName}
											control={control}
											disabled={true}
											rules={{ required: 'Store Name is required' }}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
									>
										<Controller
											name="price"
											control={control}
											render={({ field, fieldState: { error } }) => (
												<TextField
													error={!!error}
													margin="dense"
													helperText={error?.message}
													size={'small'}
													label="Total Price"
													name="price"
													fullWidth
													onChange={handlePriceChange}
													value={field.value.toFixed(2)}
													InputProps={{ startAdornment: '$' }}
													type="number"
													inputProps={{
														inputMode: 'decimal',
														pattern: '[0-9]*',
														step: '0.01',
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</Box>
							<Box>
								<Grid
									container
									spacing={{
										xs: 0,
										md: 2,
									}}
								>
									<Grid
										item
										xs={12}
										md={6}
									>
										<MyTextField
											name="employeeName"
											label="Employee Name"
											error={errors.employeeName}
											control={control}
											rules={{ required: 'Employee Name is required' }}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
									>
										<MyTextField
											name="posOrderId"
											label="POS Order ID"
											error={errors.posOrderId}
											control={control}
										/>
									</Grid>
								</Grid>
							</Box>
							<Box>
								<Grid container>
									<Box sx={{ width: '100%' }}>
										<Grid
											item
											xs={12}
										>
											<MyTextField
												name="notes"
												label="Notes"
												control={control}
												multiline={true}
												rows={4}
											/>
										</Grid>
									</Box>
								</Grid>
							</Box>
						</Grid>
					</form>
				</FormProvider>
			</Paper>
			<Box
				display="flex"
				justifyContent="flex-end"
				mt={2}
				sx={{ width: '100%', maxWidth: 600 }}
			>
				<Button
					onClick={handleSubmit(onSubmit)}
					type="submit"
					sx={{ width: 140, height: 40, backgroundColor: COLOR.orange }}
					variant="contained"
					size="large"
				>
					CHECKOUT <CameraAltIcon sx={{ ml: 1 }} />
				</Button>
			</Box>
		</Box>
	)
}
