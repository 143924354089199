import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import DeleteIcon from '@mui/icons-material/Delete'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Divider,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAlert } from 'src/lib/useAlert.tsx'
import { Account } from '../../@types/accounts'
import { COLOR } from '../../layout/constants.ts'
import { useIsValidAccount } from '../../react-query/hooks/use-isValid-account.ts'
import { useMakeAccountPrimary } from '../../react-query/hooks/use-make-account-primary.ts'
import { useRemoveExternalAccount } from '../../react-query/hooks/use-remove-external-account.ts'
import { LinkButton } from '../link-button.tsx'
import MyCard from './my-card.tsx'

type Props = {
	account: Account
}

export const AccountCard = ({ account }: Props) => {
	const [open, setOpen] = useState(false)
	const {
		IsPrimary,
		LastFour,
		VendorInstitutionName,
		SynAccountID,
		PendingAmount,
		VendorInstitutionID,
		VendorAccessToken,
	} = account
	const makeAccountPrimaryMutation = useMakeAccountPrimary()
	const removeAccountMutation = useRemoveExternalAccount()
	const { data, isLoading: loadingIsValidAccount } = useIsValidAccount(SynAccountID)
	const navigate = useNavigate()
	const { triggerSnackbar } = useAlert()

	const handleClickDelete = () => {
		if (PendingAmount > 0) {
			triggerSnackbar('Transactions are pending on the account. Please try again later', 'error')
			return
		}
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleMakeAccountPrimary = () => {
		if (account.IsPrimary) {
			return
		}
		makeAccountPrimaryMutation.mutate(SynAccountID)
	}

	const handleRemoveAccount = () => {
		removeAccountMutation.mutate(SynAccountID)
		handleClose()
	}

	const handleRelink = () => {
		navigate({
			pathname: '/link-account',
			search: `?vendorInstitutionID=${VendorInstitutionID}&vendorAccessToken=${VendorAccessToken}`,
		})
	}

	return (
		<MyCard
			sx={{
				width: '100%',
				border: IsPrimary ? '1px solid white' : undefined,
				transition: 'border 0.5s ease-in-out 0.3s',
				paddingRight: 1,
			}}
		>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to unlink this account?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>No</Button>
					<Button
						color={'error'}
						variant={'outlined'}
						onClick={handleRemoveAccount}
						autoFocus
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems={'center'}
				mb={1}
			>
				<Box
					component="span"
					display="flex"
					flexGrow={1}
					width="fit-content"
					alignItems="center"
					gap={1}
					sx={{
						color: 'white',
						fontSize: '13px',
						paddingBottom: 1,
						paddingTop: 1,
					}}
				>
					<AccountBalanceIcon /> {VendorInstitutionName}
				</Box>
				{data?.isValid && (
					<Box flexShrink={0}>
						<AnimatedComponent
							IsPrimary={IsPrimary}
							handleMakeAccountPrimary={handleMakeAccountPrimary}
						/>
					</Box>
				)}
				{!data?.isValid && !loadingIsValidAccount && (
					<Button
						onClick={handleRelink}
						sx={{
							color: COLOR.manageAccountButton,
						}}
						variant="text"
					>
						Relink Account
					</Button>
				)}
			</Box>
			<Divider sx={{ background: 'white' }} />
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems={'center'}
				mt={2}
			>
				<Typography
					sx={{
						fontWeight: 'bold',
					}}
					color="white"
				>
					Account #{LastFour}
				</Typography>
				<LinkButton
					handleClick={handleClickDelete}
					sx={{ minWidth: '20px' }}
				>
					<DeleteIcon sx={{ color: PendingAmount > 0 ? 'error.main' : '#6494C0' }} />
				</LinkButton>
			</Box>
		</MyCard>
	)
}

const AnimatedComponent = ({
	IsPrimary,
	handleMakeAccountPrimary,
}: {
	IsPrimary: boolean
	handleMakeAccountPrimary: () => void
}) => {
	const [showPrimaryText, setShowPrimaryText] = useState(IsPrimary)

	useEffect(() => {
		setShowPrimaryText(IsPrimary)
	}, [IsPrimary])

	return (
		<Box
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				minWidth: '130px',
				transition: 'all 0.5s ease-in-out',
				position: 'relative',
				width: '100%',
			}}
		>
			<Typography
				sx={{
					fontSize: 13,
					opacity: showPrimaryText ? 1 : 0,
					transition: 'opacity 0.5s ease-in-out',
					color: COLOR.manageAccountButton,
					position: 'absolute',
					right: 0,
				}}
			>
				PRIMARY
			</Typography>
			<Box sx={{ position: 'absolute' }}>
				<Button
					onClick={handleMakeAccountPrimary}
					sx={{
						fontSize: 8,
						background: '#D9D9D9',
						opacity: !showPrimaryText ? 1 : 0,
						transition: 'opacity 0.5s ease-in-out',
						':hover': {
							background: '#d9d9d963',
						},
						paddingLeft: 3,
						paddingRight: 3,
					}}
					variant="text"
				>
					SET AS PRIMARY
				</Button>
			</Box>
		</Box>
	)
}
