import ky, { KyInstance } from 'ky'

export type FetchResponse<T> = {
	count: number
	next?: string | null
	results: T[]
}

export class PaymentServiceClient {
	public httpInstance: KyInstance
	private tokenGenerator: () => Promise<string>
	private static instance: PaymentServiceClient

	private constructor(tokenGenerator: () => Promise<string>) {
		this.httpInstance = ky.create({
			prefixUrl: import.meta.env.VITE_PAYMENT_SERVICE_API_URL + '/api',
			timeout: import.meta.env.DEV ? false : 10000,
			hooks: {
				beforeRequest: [
					async (request) => {
						const accessToken = await this.getToken()
						request.headers.set('Authorization', `Bearer ${accessToken}`)
					},
				],
			},
			retry: 0, // react-query handles retries
		})
		this.tokenGenerator = tokenGenerator
	}

	public static getInstance(tokenGenerator: () => Promise<string>): PaymentServiceClient {
		if (!PaymentServiceClient.instance) {
			PaymentServiceClient.instance = new PaymentServiceClient(tokenGenerator)
		}
		return PaymentServiceClient.instance
	}

	setTokenGenerator(tokenGenerator: () => Promise<string>) {
		this.tokenGenerator = tokenGenerator
		return this.httpInstance
	}

	getToken() {
		return this.tokenGenerator()
	}
}
