import { useAuth0 } from '@auth0/auth0-react'
import { Route, Routes } from 'react-router-dom'

import AuthenticationGuard from '../../components/authentication-guard.tsx'
import { Loading } from '../../components/Loading'
import { MyPermissions } from '../../constants/my-permissions.ts'
import { MyRole } from '../../constants/my-role.ts'
import { MerchantCheckoutAwaiting } from './pages/checkout/merchant-checkout-awaiting.tsx'
import { MerchantCheckoutConfirmationPage } from './pages/checkout/merchant-checkout-confirmation-page.tsx'
import { MerchantCheckoutFail } from './pages/checkout/merchant-checkout-fail.tsx'
import { MerchantCheckoutPage } from './pages/checkout/merchant-checkout-page.tsx'
import { MerchantCheckoutReadqrPage } from './pages/checkout/merchant-checkout-readqr-page.tsx'
import { MerchantCheckoutSuccess } from './pages/checkout/merchant-checkout-success.tsx'
import { MerchantHomePage } from './pages/merchant-home-page.tsx'
import { MerchantPaymentStatus } from './pages/merchant-payment-status.tsx'
import { MerchantProfile } from './pages/merchant-profile.tsx'
import { MerchantReportsPage } from './pages/merchant-reports-page.tsx'
import { MerchantSupportPage } from './pages/merchant-support-page.tsx'
import { MerchantTipsPage } from './pages/merchant-tips.tsx'
import { RegistrationPage } from './pages/registration-page.tsx'

export const MerchantRoutes = () => {
	const { isLoading, error } = useAuth0()
	if (isLoading) {
		return <Loading />
	}

	if (error) {
		return <div>Oops... {error.message}</div>
	}

	return (
		<Routes>
			<Route
				path="/registration"
				element={
					<AuthenticationGuard
						component={RegistrationPage}
						requiredRoles={[MyRole.AdminAccount]}
					/>
				}
			/>
			<Route
				path="/"
				element={
					<AuthenticationGuard
						component={MerchantHomePage}
						requiredPermissions={[MyPermissions.ViewDashboard]}
					/>
				}
			/>
			<Route
				path="/payment-status/:id"
				element={<AuthenticationGuard component={MerchantPaymentStatus} />}
			/>
			<Route
				path="/tips"
				element={
					<AuthenticationGuard
						component={MerchantTipsPage}
						requiredPermissions={[MyPermissions.ViewTips]}
					/>
				}
			/>
			<Route
				path="/checkout"
				element={
					<AuthenticationGuard
						component={MerchantCheckoutPage}
						requiredPermissions={[MyPermissions.CreateACHDebit]}
					/>
				}
			/>
			<Route
				path="/checkout/confirmation"
				element={
					<AuthenticationGuard
						component={MerchantCheckoutConfirmationPage}
						requiredPermissions={[MyPermissions.CreateACHDebit]}
					/>
				}
			/>
			<Route
				path="/checkout/read-qr-code"
				element={
					<AuthenticationGuard
						component={MerchantCheckoutReadqrPage}
						requiredPermissions={[MyPermissions.CreateACHDebit]}
					/>
				}
			/>
			<Route
				path="/checkout/fail"
				element={
					<AuthenticationGuard
						component={MerchantCheckoutFail}
						requiredPermissions={[MyPermissions.CreateACHDebit]}
					/>
				}
			/>
			<Route
				path="/checkout/success"
				element={
					<AuthenticationGuard
						component={MerchantCheckoutSuccess}
						requiredPermissions={[MyPermissions.CreateACHDebit]}
					/>
				}
			/>
			<Route
				path="/checkout/awaiting"
				element={
					<AuthenticationGuard
						component={MerchantCheckoutAwaiting}
						requiredPermissions={[MyPermissions.CreateACHDebit]}
					/>
				}
			/>
			<Route
				path="/report"
				element={
					<AuthenticationGuard
						component={MerchantReportsPage}
						requiredPermissions={[MyPermissions.ViewReports]}
					/>
				}
			/>
			<Route
				path="/support"
				element={
					<AuthenticationGuard
						component={MerchantSupportPage}
						requiredPermissions={[MyPermissions.ViewSupport]}
					/>
				}
			/>
			{import.meta.env.VITE_FEATURE_MERCHANT_PROFILE === 'true' && (
				<Route
					path="/profile"
					element={
						<AuthenticationGuard
							component={MerchantProfile}
							requiredPermissions={[MyPermissions.ViewProfile]}
						/>
					}
				/>
			)}
		</Routes>
	)
}
