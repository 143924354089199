import { Box, Button } from '@mui/material'
import { HTTPError } from 'ky'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { PurchaseWebsocketStatus } from 'src/@types/purchase'
import { useConfirmPayment, useDenyPayment } from 'src/react-query/hooks/use-payment.ts'
import { Loading } from '../../../components/Loading'
import { useAppStore } from '../../../store/appStore.ts'
import { Title, TransactionDescription } from '../components'
import { PaymentLayout } from '../layout/PaymentLayout'

export function PaymentAwaiting() {
	const navigate = useNavigate()
	const { isSuccess, error, data, mutate: mutateConfirm, isPending: isPendingConfirm } = useConfirmPayment()
	const { mutate: mutateDeny, isPending: isPendingDeny } = useDenyPayment()
	const purchase = useAppStore((state) => state.purchaseResult)
	const setPurchaseError = useAppStore((state) => state.setPurchaseError)
	const setTransactionResult = useAppStore((state) => state.setTransactionResult)
	const transactionResult = useAppStore((state) => state.transactionResult)

	useEffect(() => {
		if (!purchase?.id) {
			navigate('/dashboard')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleError = useCallback(
		async (err: HTTPError | null) => {
			const res = await err?.response?.json()
			setPurchaseError(res?.message || 'An error occurred')
			navigate('/payment/fail')
		},
		[navigate, setPurchaseError],
	)

	useEffect(() => {
		if (isSuccess) {
			if (data) {
				setTransactionResult({
					...transactionResult,
					status: 'COMPLETED',
				} as PurchaseWebsocketStatus)
				navigate('/payment/confirmation')
			} else {
				navigate('/payment/fail')
			}
		}

		if (error) {
			handleError(error as HTTPError)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, error, handleError, isSuccess, navigate])

	const onCancel = useCallback(() => {
		if (!purchase?.id) {
			return
		}
		mutateDeny({
			purchaseId: purchase.id,
		})
		navigate('/payment')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const onConfirm = useCallback(() => {
		if (!purchase?.id) {
			return
		}
		mutateConfirm({
			purchaseId: purchase.id,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!transactionResult) {
		return <Loading />
	}

	const isLoading = isPendingConfirm || isPendingDeny

	return (
		<PaymentLayout>
			<Title>Payment Approval</Title>
			<TransactionDescription transaction={transactionResult}>
				<Box
					display="flex"
					alignItems="center"
					gap={2}
					sx={{
						backgroundColor: 'white',
						borderRadius: '8px',
						padding: '16px',
						width: '100%',
						maxWidth: '350px',
						justifyContent: 'center',
					}}
				>
					<Button
						onClick={onCancel}
						disabled={isLoading}
					>
						No
					</Button>
					<Button
						color={'error'}
						variant={'outlined'}
						onClick={onConfirm}
						disabled={isLoading}
						autoFocus
					>
						Yes
					</Button>
				</Box>
			</TransactionDescription>
		</PaymentLayout>
	)
}
