import { useAuth0 } from '@auth0/auth0-react'
import { Button, ButtonProps } from '@mui/material'

type Props = {
	variant?: ButtonProps['variant']
	sx?: ButtonProps['sx']
}
export function LoginBtn({ variant = 'contained', sx = {} }: Props) {
	const { loginWithRedirect } = useAuth0()

	return (
		<Button
			onClick={() => loginWithRedirect()}
			variant={variant}
			sx={sx}
		>
			Log In
		</Button>
	)
}
