export enum MyPermissions {
	ReadPurchases = 'pc:read:purchases',
	ReadExternalAccounts = 'pc:read:external_accounts',
	DeleteExternalAccounts = 'pc:delete:external_accounts',
	ViewStore = 'pc:view:store',
	CreateSelf = 'pc:create:users:self',
	CreateExternalAccounts = 'pc:create:external_accounts',
	CreatePurchases = 'pc:create:purchases',
	CreateACHDebit = 'pc:create:ach_debit',
	CreateBusinessUsers = 'pc:create:businessuser',
	ReadBusinessUsers = 'pc:read:businessuser',
	UpdateBusinessUsers = 'pc:update:businessuser',
	DeleteBusinessUsers = 'pc:delete:businessuser',
	ViewDashboard = 'pc:view:dashboard',
	ViewTips = 'pc:view:tips',
	ViewReports = 'pc:view:reports',
	ViewUsers = 'pc:view:users',
	CreateUsers = 'pc:create:users',
	ViewSupport = 'pc:view:support',
	ViewProfile = 'pc:view:profile',
}

export const mapStringPermissionToEnum = (permission: string): MyPermissions | undefined => {
	return Object.values(MyPermissions).find((enumValue) => enumValue === permission) as MyPermissions | undefined
}

export const mapStringArrayToPermissionsEnum = (permissions: string[]): MyPermissions[] => {
	return permissions
		.map(mapStringPermissionToEnum)
		.filter((permission): permission is MyPermissions => permission !== undefined)
}
