import { Button, ButtonProps, styled } from '@mui/material'
import { ReactNode } from 'react'

type Props = ButtonProps & {
	handleClick: () => void
	children: ReactNode
}

const AppButton = styled(Button)({
	textTransform: 'none',
	color: 'primary.main',
	fontWeight: 'bold',
	padding: 0,
	'&:hover': {
		backgroundColor: 'transparent',
		textDecoration: 'underline',
	},
})

export const LinkButton = ({ handleClick, children, ...otherProps }: Props) => {
	return (
		<AppButton
			onClick={handleClick}
			{...otherProps}
		>
			{children}
		</AppButton>
	)
}
