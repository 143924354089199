import { SxProps, Typography } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
	children: ReactNode
	sx?: SxProps
}
export const TopText = ({ children, sx }: Props) => {
	return (
		<Typography
			variant="h3"
			sx={{
				fontSize: '20px',
				textAlign: 'center',
				maxWidth: '360px',
				margin: '0 auto 20px',
				color: '#FFFFFF',
				fontWeight: '500',
				...sx,
			}}
		>
			{children}
		</Typography>
	)
}
