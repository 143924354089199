import { Box, InputBaseComponentProps, TextField } from '@mui/material'
import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form'

import { COLOR } from 'src/layout/constants'

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> & {
	label?: string
	error?: FieldError
	disabled?: boolean
	inputProps?: InputBaseComponentProps
	multiline?: boolean
	rows?: number
	size?: 'small' | 'medium'
	variant?: 'standard' | 'filled' | 'outlined'
	type?: React.HTMLInputTypeAttribute | undefined
	onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
	onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
	placeholder?: string
	background?: string
}
export function MyTextField<TFieldValues extends FieldValues>({
	name,
	control,
	label,
	rules,
	disabled,
	defaultValue,
	inputProps,
	multiline,
	rows,
	size = 'small',
	variant = 'outlined',
	type,
	onBlur,
	onFocus,
	placeholder,
	background,
}: Props<TFieldValues>) {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<>
					<TextField
						sx={{
							background,
						}}
						name={name}
						type={type}
						variant={variant}
						multiline={multiline}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
						disabled={disabled}
						value={value}
						margin="dense"
						size={size}
						fullWidth
						label={label}
						error={!!error}
						helperText={!background && error?.message}
						inputProps={inputProps}
						rows={rows}
						placeholder={placeholder}
					/>
					{background && <Box sx={{ fontSize: 12, color: COLOR.error }}>{error?.message}</Box>}
				</>
			)}
			rules={rules}
		/>
	)
}
