import { Box, Button, Modal, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Alert from '../assets/images/error/alert.svg'

export const LinkAccountModalToken = () => {
	const navigate = useNavigate()

	return (
		<Modal open={true}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				gap={2}
				sx={{
					padding: '16px',
					width: '95vw',
					maxWidth: '350px',
					marginX: 'auto',
					marginTop: '30vh',
					justifyContent: 'center',
					outline: 'none',
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					gap={2}
					sx={{
						backgroundColor: 'white',
						borderRadius: '8px',
						padding: '16px',
						width: '100%',
						maxWidth: '350px',
						justifyContent: 'center',
					}}
				>
					<img
						src={Alert}
						style={{ width: '85px', height: '85px' }}
					/>
					<Typography
						variant="h6"
						sx={{ color: '#B30A0A' }}
					>
						Account token is expired. <br />
						Link Account to continue.
					</Typography>

					<Button
						onClick={() => {
							navigate({
								pathname: '/dashboard/profile/manage-linked-accounts',
							})
						}}
						variant="contained"
						color="secondary"
						size="large"
						fullWidth={true}
					>
						Manage Accounts
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}
