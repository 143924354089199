import LockIcon from '@mui/icons-material/Lock'
import WifiIcon from '@mui/icons-material/Wifi'
import { Box, Button, Card, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { DashboardLayout } from 'src/components/dashboard-components/dashboard-layout.tsx'
import { useUser } from 'src/react-query/hooks/use-user.ts'
import { Loading } from '../components/Loading'
import { TopText } from '../components/top-text.tsx'
import { usePlaidToken } from '../react-query/hooks/use-plaid-token.ts'

type Content = {
	title: string
	text: string
	icon: ReactNode
}

const listItemsContent: Content[] = [
	{
		title: 'Connect effortlessly',
		text: 'Plaid lets you securely connect your financial accounts in seconds',
		icon: <WifiIcon fontSize={'large'} />,
	},
	{
		title: 'Protect your accounts',
		text: 'Plaid helps minimize fraud and risk by using  accounts info, transaction history, and  connection history',
		icon: <LockIcon fontSize={'large'} />,
	},
]

export function LinkAccountPage() {
	const [searchParams] = useSearchParams()
	const { isLoading: queryRunning, error: userError } = useUser()
	const {
		open,
		ready,
		token,
		error,
		loading: plaidLoading,
		linkTokenError,
	} = usePlaidToken({
		redirectTo: searchParams.get('redirectTo'),
		vendor_access_token: searchParams.get('vendorAccessToken'),
		vendor_institution_id: searchParams.get('vendorInstitutionID'),
	})
	const navigate = useNavigate()

	if (error || userError || linkTokenError) {
		return <div>An error occurred: {error?.message || userError?.message || linkTokenError?.message}</div>
	}

	if (queryRunning || plaidLoading || !token) {
		return <Loading />
	}

	return (
		<DashboardLayout>
			<Box sx={{ px: '17px' }}>
				<TopText>Connect your account easily</TopText>
				<Card
					sx={{
						background: 'white',
						display: 'flex',
						flexDirection: 'column',
						p: 4,
						borderRadius: 4,
					}}
				>
					{listItemsContent.map((content, index) => (
						<Box
							key={index}
							sx={{
								marginBottom: '30px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<Typography sx={{ marginBottom: 1, color: 'primary.main' }}>{content.icon}</Typography>
							<Typography
								variant="h6"
								sx={{ fontWeight: '700', marginBottom: 1 }}
							>
								{content.title}
							</Typography>
							<Typography variant="body2">{content.text}</Typography>
						</Box>
					))}

					<Button
						variant="contained"
						size="large"
						color={'secondary'}
						sx={{ marginTop: '20px', alignSelf: 'center' }}
						onClick={() => open()}
						disabled={!ready}
					>
						Continue
					</Button>
					<Button
						variant="text"
						color="primary"
						sx={{
							marginTop: '16px',
							cursor: 'pointer',
						}}
						onClick={() => {
							navigate('/first-login-tutorial')
						}}
					>
						HOW IT WORKS
					</Button>
				</Card>
			</Box>
		</DashboardLayout>
	)
}
