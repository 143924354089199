import { Container, Typography } from '@mui/material'
import * as React from 'react'

import { COLOR } from '../../../layout/constants'

type Props = {
	title: string
	children: React.ReactNode
	style?: React.CSSProperties
}

export const MerchantLayout = ({ children, title, style }: Props) => {
	return (
		<Container
			data-testid="MerchantLayout"
			sx={{ padding: '15px 0px' }}
			style={style}
		>
			<Typography
				variant="h1"
				sx={{
					fontSize: '18px',
					fontWeight: '600',
					lineHeight: '29.88px',
					textAlign: 'center',
					color: COLOR.white,
					marginBottom: '10px',
				}}
			>
				{title}
			</Typography>

			{children}
		</Container>
	)
}
