import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Loading } from '../../../components/Loading'
import { useAppStore } from '../../../store/appStore.ts'
import { Title, TransactionDescription, TransactionStatus } from '../components'
import { PaymentLayout } from '../layout/PaymentLayout'

export function PaymentConfirmation() {
	const navigate = useNavigate()
	const transactionResult = useAppStore((state) => state.transactionResult)

	useEffect(() => {
		if (!transactionResult) {
			navigate('/dashboard')
		}
	}, [])

	const status = useMemo(() => {
		return transactionResult?.status === 'COMPLETED' ? 'success' : 'error'
	}, [transactionResult])

	if (!transactionResult) {
		return <Loading />
	}

	return (
		<PaymentLayout>
			<Title>Payment Confirmation</Title>
			<TransactionStatus status={status} />
			<TransactionDescription transaction={transactionResult} />
		</PaymentLayout>
	)
}
