import { Box, Paper } from '@mui/material'

import Alert from '../assets/images/error/alert.svg'
import { AlertPage } from '../components/error/AlertPage'

type Props = {
	error?: Error | null
	navigate?: (path: string) => void
	resetError?: () => void
}

export function ErrorPage({ navigate, resetError }: Props) {
	const onClick = () => {
		if (resetError) {
			resetError()
		}
		if (navigate) {
			navigate('/logout')
		}
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			mt={20}
			sx={{ padding: 2 }}
		>
			<Paper sx={{ p: 4, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}>
				<Box data-testid="ErrorPage">
					<AlertPage
						title={
							<>
								Error, Please contact
								<br />
								Paybotic Financial directly
							</>
						}
						image={{ src: Alert }}
						buttom={{ text: 'Re Authenticate', onClick }}
					/>
				</Box>
			</Paper>
		</Box>
	)
}
