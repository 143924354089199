import { Skeleton, TableCell, TableRow } from '@mui/material'

type TableRowsLoaderProps = {
	rowsNum: number
	colsNum: number
}

export const TableRowsLoader = ({ rowsNum = 4, colsNum = 4 }: TableRowsLoaderProps) => {
	return [...Array(rowsNum)].map((_, index) => (
		<TableRow key={index}>
			<TableCell
				component="th"
				scope="row"
			>
				<Skeleton
					animation="wave"
					variant="text"
				/>
			</TableCell>
			{[...Array(colsNum - 1)].map((_, index) => (
				<TableCell key={index}>
					<Skeleton
						animation="wave"
						variant="text"
					/>
				</TableCell>
			))}
		</TableRow>
	))
}
