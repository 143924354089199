import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> & {
	label: string
	disabled?: boolean
	minDate?: dayjs.Dayjs | null
	maxDate?: dayjs.Dayjs | null
}
export function MyDateField<TFieldValues extends FieldValues>({
	name,
	control,
	label,
	rules,
	disabled,
	defaultValue,
	minDate,
	maxDate,
}: Props<TFieldValues>) {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { onChange, value } }) => {
				return (
					<DatePicker
						sx={{ width: '100%' }}
						onChange={onChange}
						disabled={disabled}
						value={dayjs(value)}
						label={label}
						minDate={minDate}
						maxDate={maxDate}
					/>
				)
			}}
			rules={rules}
		/>
	)
}
