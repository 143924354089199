import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import { useCallback } from 'react'

import { getLogoutOptions } from 'src/utils/logout-utils'
import FileImage from '../assets/images/error/file.svg'
import { AlertPage } from '../components/error/AlertPage'

export function AccountUnderReview() {
	const { logout } = useAuth0()

	const onClick = useCallback(() => {
		logout(getLogoutOptions())
	}, [logout])

	return (
		<Box data-testid="ErrorPage">
			<AlertPage
				title={
					<>
						Sorry, your profile is
						<br />
						under review. Come back <br />
						in 24 hours
					</>
				}
				image={{ src: FileImage }}
				buttom={{ text: 'Log Out', onClick }}
			/>
		</Box>
	)
}
