import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import {
	Box,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material'

import { DataCard } from '../components/DataCard.tsx' // Grid version 2

function createData(name: string, calories: string, fat: string, carbs: string, protein: string) {
	return { name, calories, fat, carbs, protein }
}

const rows = [
	createData('Company Name 1', '$50.00', '3', '$23.00', '$10.00'),
	createData('Company Name 2', '$50.00', '3', '$23.00', '$10.00'),
	createData('Company Name 3', '$50.00', '3', '$23.00', '$10.00'),
]

export function MerchantTipsPage() {
	return (
		<>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
					md={4}
				>
					<DataCard
						icon={<AttachMoneyIcon />}
						title={'Total tips'}
						label={'$150'}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
				>
					<DataCard
						icon={<AttachMoneyIcon />}
						title={'Average Tip per Transaction'}
						label={'45'}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
				>
					<DataCard
						icon={<AttachMoneyIcon />}
						title={'Average Monthly Tips'}
						label={'$500'}
					/>
				</Grid>
			</Grid>
			<TableContainer
				sx={{ mt: 2 }}
				component={Paper}
			>
				<Box sx={{ p: 2 }}>
					<Typography
						sx={{
							fontSize: 16,
							fontWeight: 600,
						}}
					>
						Storewise Transactions Details
					</Typography>
				</Box>
				<Table
					sx={{ minWidth: 650 }}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow sx={{ background: '#CDD7E1' }}>
							<TableCell align="left">Store name</TableCell>
							<TableCell align="center">Total Sales</TableCell>
							<TableCell align="center">Avg. Tip per Transaction</TableCell>
							<TableCell align="center">Total Tips</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow
								key={row.name}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell
									align="left"
									component="th"
									scope="row"
								>
									{row.name}
								</TableCell>
								<TableCell align="center">{row.calories}</TableCell>
								<TableCell align="center">{row.fat}</TableCell>
								<TableCell align="center">{row.carbs}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
