import { useMutation } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { PurchaseResponse } from '../../@types/purchase'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

type CreatePurchaseRequest = {
	purchase: string
	amount: number
	notes?: string
	pos_order_id?: string
}

type ConfirmPurchaseRequest = {
	purchaseId: string
}

async function initiatePayment(httpInstance: KyInstance, body: CreatePurchaseRequest): Promise<PurchaseResponse> {
	const response = await httpInstance.post('purchases/create-debit', {
		json: body,
	})

	return await response.json<PurchaseResponse>()
}

export function useCreatePayment() {
	const { paymentServiceClient } = usePaymentService()
	return useMutation<PurchaseResponse, Error, CreatePurchaseRequest, string>({
		mutationFn: async (request: CreatePurchaseRequest) => {
			return initiatePayment(paymentServiceClient.httpInstance, request)
		},
		onSuccess: (data: unknown) => {
			console.log(data)
		},
		onError: (error) => {
			console.log(error)
		},
	})
}

async function confirmPayment(httpInstance: KyInstance, body: ConfirmPurchaseRequest): Promise<PurchaseResponse> {
	const response = await httpInstance.post('purchases/confirm-debit', {
		json: body,
	})

	return await response.json<PurchaseResponse>()
}

export function useConfirmPayment() {
	const { paymentServiceClient } = usePaymentService()
	return useMutation<PurchaseResponse, Error, ConfirmPurchaseRequest, string>({
		mutationFn: async (request: ConfirmPurchaseRequest) => {
			return confirmPayment(paymentServiceClient.httpInstance, request)
		},
		onSuccess: (data: unknown) => {
			console.log(data)
		},
		onError: (error) => {
			console.log(error)
		},
	})
}

async function denyPayment(httpInstance: KyInstance, body: ConfirmPurchaseRequest): Promise<PurchaseResponse> {
	const response = await httpInstance.post('purchases/deny-debit', {
		json: body,
	})

	return await response.json<PurchaseResponse>()
}

export function useDenyPayment() {
	const { paymentServiceClient } = usePaymentService()
	return useMutation<PurchaseResponse, Error, ConfirmPurchaseRequest, string>({
		mutationFn: async (request: ConfirmPurchaseRequest) => {
			return denyPayment(paymentServiceClient.httpInstance, request)
		},
		onSuccess: (data: unknown) => {
			console.log(data)
		},
		onError: (error) => {
			console.log(error)
		},
	})
}
