const COLOR = {
	primary: '#2F587E',
	darkPrimary: '#214E75',
	successText: '#01579B',
	manageAccountButton: '#6A9BCA',
	errorLight: '#C62828',
	error: '#B30A0A',
	white: '#ffffff',
	orange: '#FF9100',
	textColor: '#12467B',
	darkBlue: '#244D74',
	green: '#09AB23',
}
Object.freeze(COLOR)

export { COLOR }
