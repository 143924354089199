import { useAuth0 } from '@auth0/auth0-react'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Box, Button, Card, Container, List, ListItem, ListItemText, Modal, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getLogoutOptions } from 'src/utils/logout-utils.ts'
import { Loading } from '../components/Loading'
import { TopText } from '../components/top-text.tsx'
import { useUser } from '../react-query/hooks/use-user.ts'

export function FirstLoginTutorialPage() {
	const { isLoading: queryRunning } = useUser()
	const { logout } = useAuth0()
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)

	const listItemsContent = [
		'Link Bank Account',
		'Visit a Participating Merchant',
		"Use 'Pay Now' Button to checkout at the register",
		'Merchant will scan the QR code for a quick & easy checkout',
	]

	if (queryRunning) {
		return <Loading />
	}

	return (
		<Box
			mt={2}
			sx={{
				padding: {
					xs: 2,
					sm: 4,
				},
				maxWidth: 600,
				width: '100%',
				mx: 'auto',
				mt: 2,
			}}
		>
			<Container
				sx={{
					py: {
						xs: 2,
						sm: 4,
					},
				}}
			>
				<TopText>Before you begin, link your bank account</TopText>

				<Card
					sx={{
						background: 'white',
						py: 4,
						px: {
							xs: 3,
							sm: 4,
						},
						borderRadius: 4,
					}}
				>
					<Typography
						variant="h5"
						sx={{
							fontSize: '1.25rem',
							textAlign: 'center',
							fontWeight: '700',
							mb: 2,
						}}
					>
						How it works
					</Typography>

					<List sx={{ mb: 3, pl: 4, listStyle: 'decimal' }}>
						{listItemsContent.map((content, index) => (
							<ListItem
								key={index}
								sx={{ py: 0.8, px: 0, display: 'list-item' }}
							>
								<ListItemText
									primary={content}
									primaryTypographyProps={{
										fontWeight: 400,
										fontSize: 14,
									}}
								/>
							</ListItem>
						))}
					</List>
					<Box
						display="flex"
						flexDirection="column"
						gap={3}
						mt={2}
					>
						<Button
							variant="contained"
							size="large"
							onClick={() => {
								setShowModal(true)
							}}
						>
							<PlayCircleOutlineIcon /> Watch Video
						</Button>
						<Button
							variant="contained"
							size="large"
							color={'secondary'}
							onClick={() => {
								navigate('/link-account')
							}}
						>
							Link Bank Account
						</Button>
						<Button
							variant="text"
							onClick={() => logout(getLogoutOptions())}
						>
							Log out
						</Button>
					</Box>
				</Card>

				<Modal
					onClose={() => {
						setShowModal(false)
					}}
					open={showModal}
				>
					<Box
						onClick={() => {
							setShowModal(false)
						}}
						display="flex"
						flexDirection="column"
						alignItems="center"
						gap={2}
						sx={{
							justifyContent: 'center',
							height: '100%',
							width: '100%',
						}}
					>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							gap={2}
							sx={{
								justifyContent: 'center',
								backgroundColor: 'white',
								padding: '20px',
								borderRadius: '20px',
							}}
						>
							<iframe
								width="350px"
								height="350px"
								src="https://www.youtube.com/embed/QfkFue9Een0"
								title="Paybotic Tutorial Video"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerPolicy="strict-origin-when-cross-origin"
								allowFullScreen
							></iframe>
						</Box>
					</Box>
				</Modal>
			</Container>
		</Box>
	)
}
