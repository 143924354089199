import { useQuery } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'

import { ReportData } from '../../@types/merchant'
import { Nullable } from '../../@types/nullable'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

export type BodyRequest = {
	start_date?: string
	end_date?: string
	store_id?: string
}

async function getReport(httpInstance: KyInstance, body: BodyRequest) {
	try {
		const response = await httpInstance.post('businesses/report', {
			body: JSON.stringify(body),
		})
		return response.json<ReportData[]>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export function useReport(body: BodyRequest) {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<Nullable<ReportData[]>, Error, Nullable<ReportData[]>>({
		queryKey: ['report', body], // Include body in the query key to trigger re-fetching on changes
		queryFn: () => getReport(paymentServiceClient.httpInstance, body),
		enabled: !isLoading && isAuthenticated,
	})
}
