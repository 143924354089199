import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { useAlert } from 'src/lib/useAlert.tsx'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

const updateBURole = async (httpInstance: KyInstance, buAuth0Id: string, role: string) => {
	await httpInstance.post(`businesses/user/role`, {
		json: {
			role,
			auth0_user_id: buAuth0Id,
		},
	})
}

export function useUpdateBURole() {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()

	return useMutation<void, Error, { buAuth0Id: string; role: string }, string>({
		mutationFn: async ({ buAuth0Id, role }) => {
			return updateBURole(paymentServiceClient.httpInstance, buAuth0Id, role)
		},
		onSuccess: async () => {
			triggerSnackbar('Role updated successfully', 'success')
			await queryClient.invalidateQueries({
				queryKey: ['business-users'],
			})
		},
		onError: (error) => {
			console.error(error)
			triggerSnackbar('Role update failed', 'error')
		},
	})
}
