import { Box, Typography } from '@mui/material'
import React from 'react'

export const ProfileItem = ({
	label,
	value,
	color = '#12467B',
}: {
	label: string
	value: React.ReactNode
	color?: string
}) => (
	<Box sx={{ marginBottom: '5px' }}>
		<Typography
			component="span"
			variant="body1"
			sx={{
				fontSize: '14px',
				fontWeight: '600',
				lineHeight: '19.88px',
				color: color,
			}}
		>
			<Typography
				component="span"
				variant="body1"
				sx={{
					fontSize: '14px',
					fontWeight: '400',
					lineHeight: '19.88px',
				}}
			>
				{label}:
			</Typography>{' '}
			{value}
		</Typography>
	</Box>
)
