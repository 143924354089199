import { Box, Button, Card, CardContent, styled, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { PurchaseWebsocketStatus } from 'src/@types/purchase'
import { usePurchaseWebSocket } from 'src/modules/payment/hooks/use-purchase-websocket.ts'
import { COLOR } from '../../../../layout/constants.ts'
import { ErrorPage } from '../../../../pages/error-page.tsx'
import { useAppStore } from '../../../../store/appStore.ts'
import { dateFormatter } from '../../../../utils/dates.ts'
import { formatCentsToCurrency } from '../../../../utils/money-utils.ts'

const StyledCard = styled(Card)(() => ({
	margin: '0 auto',
	background: COLOR.white,
	borderRadius: 1,
	maxWidth: '100%',
	marginBottom: '20px',
	'& .MuiCardContent-root': {
		padding: 0,
	},
}))

const Data = styled('span')(() => ({
	fontSize: '14px',
	fontWeight: 600,
	lineHeight: '21px',
}))

export function MerchantCheckoutAwaiting() {
	const navigate = useNavigate()
	const purchase = useAppStore((state) => state.purchaseResult)
	const setTransactionResult = useAppStore((state) => state.setTransactionResult)
	const setPurchaseError = useAppStore((state) => state.setPurchaseError)
	const setPurchase = useAppStore((state) => state.setPurchase)

	const onWebhook = (purchaseWebsocketStatus: PurchaseWebsocketStatus) => {
		setTransactionResult(purchaseWebsocketStatus)
		switch (purchaseWebsocketStatus.status) {
			case 'COMPLETED':
				setPurchase(null)
				navigate('/merchant/checkout/success')
				return
			case 'DENIED':
				setPurchaseError('The customer denied')
				break
			default:
				setPurchaseError('An error occurred')
				break
		}
		navigate('/merchant/checkout/fail')
	}

	usePurchaseWebSocket(purchase?.id, onWebhook)

	// this is needed to mimic the QR webhook message in the E2E test
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	;(window as any).onWebhook = onWebhook

	useEffect(() => {
		if (!purchase) {
			navigate('/merchant/checkout')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!purchase) {
		return <ErrorPage />
	}

	return (
		<Box
			mt={2}
			sx={{ padding: 2, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}
		>
			<StyledCard>
				<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<div
						style={{
							height: 56,
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							background: '#CDD7E1',
						}}
					>
						<Typography
							color="primary"
							variant="h6"
							sx={{ fontSize: 14, fontWeight: 600 }}
						>
							Payment Awaiting Confirmation
						</Typography>
					</div>
					<Box sx={{ px: '30px', pt: '10px', pb: '20px', width: '100%' }}>
						<Typography variant="subtitle1">
							Transaction Date:{' '}
							<Data data-testid="checkout-purchase-date">{dateFormatter.format(new Date(purchase.purchase_date))}</Data>
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ mb: 2 }}
						>
							Transaction ID: <Data data-testid="checkout-purchase-public-id">{purchase.public_id}</Data>
						</Typography>
						<Typography variant="subtitle1">
							Store Name: <Data data-testid="checkout-purchase-facility-name">{purchase.facility_name}</Data>
						</Typography>
						<Typography variant="subtitle1">
							Client Name: <Data data-testid="checkout-purchase-consumer-name">{purchase.consumer_name}</Data>
						</Typography>
						<Typography
							sx={{ mb: 2 }}
							variant="subtitle1"
						>
							POS Order ID: <Data data-testid="checkout-purchase-pos-order-id">{purchase.pos_order_id || '-'}</Data>
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ fontWeight: 'bold' }}
						>
							Total: <Data data-testid="checkout-purchase-amount">{formatCentsToCurrency(purchase.amount)}</Data>
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ fontWeight: 'bold' }}
						>
							Fee: <Data data-testid="checkout-purchase-fee-amount">{formatCentsToCurrency(purchase.fee_amount)}</Data>
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ mb: 2 }}
						>
							Notes: <Data data-testid="checkout-purchase-notes">{purchase.notes || '-'}</Data>
						</Typography>
						<Box sx={{ display: 'flex', gap: 1 }}>
							<Button
								component={Link}
								to="/merchant/checkout"
								variant="contained"
								sx={{ bgcolor: 'orange', '&:hover': { bgcolor: 'darkorange' } }}
							>
								New Checkout
							</Button>
							<Button
								component={Link}
								to="/merchant"
								variant="outlined"
							>
								Home
							</Button>
						</Box>
					</Box>
				</CardContent>
			</StyledCard>
		</Box>
	)
}
