import { Box, Button, Skeleton } from '@mui/material'

import { Transaction } from 'src/react-query/hooks/use-transaction.ts'
import MyCard from './my-card.tsx'
import { Transaction as TransactionItem } from './Transaction.tsx'

type TransactionsListProps = {
	data: Transaction[]
	isLoading: boolean
	showSeeMoreButton?: boolean
	seeMoreOnClick: () => void
}

export function TransactionsList({ data, isLoading, showSeeMoreButton, seeMoreOnClick }: TransactionsListProps) {
	if (isLoading) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				<Skeleton
					width="100%"
					height={20}
					sx={{ transform: 'scale(1)' }}
				/>

				<Skeleton
					width="100%"
					height={267}
					sx={{ transform: 'scale(1)' }}
				/>
			</Box>
		)
	}

	if (!data.length) return null

	return (
		<MyCard data-testid="transactions-list">
			{data.map((transaction, index) => (
				<TransactionItem
					key={index}
					{...transaction}
				/>
			))}
			{showSeeMoreButton && (
				<Button
					fullWidth={true}
					onClick={seeMoreOnClick}
					sx={{
						color: 'white',
						fontSize: '12px',
						textTransform: 'none',
						marginTop: '1rem',
						textDecoration: 'underline',
					}}
					variant="text"
				>
					See more
				</Button>
			)}
		</MyCard>
	)
}
